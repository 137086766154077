import './style.css'

const Number = ({ data, variacion=undefined, lable, icon }) => {
    return (
        <div className='number'>
            <div className='lable-group'>
                <p className='lable'>{lable}</p>
                <p className='icon'>{icon}</p>
            </div>
            <div className='data-group'>
                <p>{data}</p>
                <p className='variation'>{variacion}</p>
            </div>
        </div>
    )
}

export default Number
