// import './style.css'
import { Button } from 'react-bootstrap'
import { dispatchCustomEvent } from '../../../utils/dispatch';
import "./style.css"

function ButtonHub(props) {

    function handleClick(){
        dispatchCustomEvent('modal'+props.modalID,[])
        if(props.onClick?props.onClick:false) {props.onClick()}
    }

    return (
        <Button
            id={props.id ? "modal-btn-hub-" + props.id : "modal-btn-hub"}
            aria-disabled={true}
            className='btn-hub'
            onClick={handleClick}
            type="button"
        >
            <p className='icon-wrapper'>{props.icon}</p>
            <p className='label'>{props.title}</p>
        </Button>
    )
}

export default ButtonHub
