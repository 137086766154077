import { userSingleton } from "../../../classes/User";
import ThirdsRepositoryImp from '../../../infrastructure/thirds/thirds-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const repo = new ThirdsRepositoryImp();

function createThird(data, type_, func) {
    const suscription = repo.create(userSingleton.uid, userSingleton.cid, data, type_).subscribe(
        (data) => {
            var allInputs = document.querySelectorAll('input');
            allInputs.forEach(singleInput => singleInput.value = '');
            dispatchCustomEvent("notification-popup", {message: "Tercero creado exitosamente"})
            dispatchCustomEvent("close-new-third-modal", [])
            dispatchCustomEvent("update-thirds", [])
            func({})
        },
        (error) => {
            if (error.response.status == 409) {
                dispatchCustomEvent("notification-popup", {message: "Documento de identidad repetido", type:"error"})
            }else{
                dispatchCustomEvent("notification-popup", {message: "Error creando tercero", type:"error"})
            }
            dispatchCustomEvent("loading-btn-third-new", {})
        }
    )
    return () => suscription.unsubscribe()
}

export { createThird }
