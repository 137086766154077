import { getTotal } from '../../../domain/usecase/carrito/carrito';

export function handleSubTotal(selectedMethods) {
    var totalToPay = parseFloat(getTotal())
    var sumPayMethods = 0
    if (selectedMethods.length === 0) {
        return [0, 0]
    }
    selectedMethods.map((id) => {
        sumPayMethods += parseFloat(window.document.getElementById("pay-"+id)?.value)
    })
    var diferenceTotalSubtotal = totalToPay - sumPayMethods
    if (isNaN(diferenceTotalSubtotal)) {
        diferenceTotalSubtotal = totalToPay
    }
    if (diferenceTotalSubtotal >= 0) {
        return [0, diferenceTotalSubtotal]
    } else {
        return [diferenceTotalSubtotal, 0]
    }
}
