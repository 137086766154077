import { BuysRepository } from '../../domain/models/buy/gateway/buys-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class BuysRepositoryImp extends BuysRepository {
  create(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post(`/buy`, data);
  }

  get(uid: string, cid: string | null): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get(`/buy`);
  }

  getById(uid: string, cid: string | null, id: string): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get(`/buy/items/${id}`);
  }

  edit(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.put(`/buy`, data);
  }

  delete(uid: string, cid: string | null, id: string) {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.delete(`/buy/${id}`);
  }
}
