import Pagination from 'react-js-pagination'
import './style.css'
import { useState } from 'react';


const PaginationPos = ({id, itemsCountPerPage, totalItemsCount, getFunction, setFunction, setActive}) => {

    const [activePage, setActivePage] = useState(1);

    function handleChange(page) {
        setFunction({"data": []})
        setActivePage(page)
        setActive(page)
        getFunction(page, itemsCountPerPage, setFunction)
    }

    return (
        <div className='pagination-container' id={"pagination-"+id}>
            <Pagination
                linkClass="pageLink"
                activeClass="pageActive"
                activePage={activePage}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={totalItemsCount}
                onChange={(pageNumber) => handleChange(pageNumber)}
            />
        </div>
    )
}

export default PaginationPos;
