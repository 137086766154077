import { userSingleton } from "../../../classes/User";
import SuscriptionRepositoryImp from "../../../infrastructure/suscriptions/suscription-repository-implementation";

const repository = new SuscriptionRepositoryImp();

function getPlans(func) {
    const suscription = repository.getAllPlans(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => {
            const filteredData = data.filter(plan => plan.id === 1 || plan.id === 4);
            func(filteredData)
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()
}

export { getPlans }
