import { userSingleton } from "../../../classes/User";
import ProductsRepositoryImp from "../../../infrastructure/products/products-repository-implementation";
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new ProductsRepositoryImp();

function uploadFromFile(body, func) {
    const suscription = repo.createFromFile(userSingleton.uid, userSingleton.cid, body).subscribe(
        () => {
            dispatchCustomEvent("notification-popup", {message: "Productos cargados correctamente"})
            dispatchCustomEvent("update-stock",[])
            dispatchCustomEvent('close-loadStockFromFile-modal', [])
            func(false)
        },
        (error) => {
            if ( error.response.data.message === "product_exist") {
                dispatchCustomEvent("notification-popup", {message: "Error, uno o varios sku existen.", type:"error"})
            }else{
                dispatchCustomEvent("notification-popup", {message: "Error cargando productos", type:"error"})
            }
            func(false)
        }
    )
    return () => suscription.unsubscribe()
}

export { uploadFromFile }
