import { InvoiceRepository } from '../../domain/models/invoice/gateway/invoice-repository';
import { Invoice, InvoicePDF } from '../../domain/models/invoice/invoice';
import { InvoiceInformation } from '../../domain/models/invoice/invoice-information';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class InvoiceRepositoryImp extends InvoiceRepository {

  create(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post<any>(`invoice`, data);
  }

  getAll(uid: string, cid: string | null, page: number, perPage: number): Observable<Invoice[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Invoice[]>(`invoice?page=${page}&perPage=${perPage}`);
  }

  getByNumber(uid: string, cid: string | null, number: number, prefix: string): Observable<Invoice[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Invoice[]>(`invoice/number/${number}/${prefix}`);
  }

  getInvoiceByCude(uid: string, cid: string | null, cude: string): Observable<Invoice> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<Invoice>(`invoice/cude/${cude}`);
  }

  getInvoiceInformation(uid: string, cid: string | null): Observable<InvoiceInformation> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<InvoiceInformation>(`invoice/data`);
  }

  updateInvoiceInformation(uid: string, cid: string | null, data: any): Observable<InvoiceInformation> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.put<InvoiceInformation>(`invoice/data`, data);
  }

  getResolutions(uid: string, cid: string | null): Observable<string[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<string[]>(`invoice/resolutions`);
  }

  isEnable(uid: string, cid: string | null): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<any>(`invoice/enable`);
  }

  getPdf(uid: string, cid: string | null, cude: string): Observable<InvoicePDF> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<InvoicePDF>(`invoice/pdf/${cude}`);
  }
}
