import { userSingleton } from "../../../classes/User";
import InvoiceRepositoryImp from '../../../infrastructure/invoice/invoice-repository-implementation'

const repo = new InvoiceRepositoryImp();

function getIsInvoiceEnable(func) {
    const suscription = repo.isEnable(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => {
            if ("electronic_invoice" in data && data["electronic_invoice"] == 1) {
                func(true);
            } else {
                func(false);
            }
        },
        (error) => {
            func(true);
        }
    );
    return () => suscription.unsubscribe()
}

export { getIsInvoiceEnable }
