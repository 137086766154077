//Custo css import
import './style.css'
import GeneralLayout from '../../templates/general/view'
import Table from '../../moleculas/tablas/view'
import Tab from '../../moleculas/tab/view'
import { useEffect, useState } from 'react'
import { userSingleton } from '../../classes/User'
import { createPdfBlob, createPdfUrl, enviarMensaje } from './controller'
import ButtonAction from '../../atomos/buttons/buttonAction/view'
import { dispatchCustomEvent } from '../../utils/dispatch'
import ButtonGroup from '../../moleculas/buttonGroup/view'
import InvoiceRepositoryImp from '../../infrastructure/invoice/invoice-repository-implementation'
import InvoiceDataForm from '../../invoices/invoiceDataForm/view'
import { getNotasCredito } from '../../domain/usecase/notascredito/get-notascredito'
import SearchBar from '../../shared/atomos/searchbar/search'
import { ButtonBasic } from '../../atomos/buttons'
import PaginationPos from '../../shared/atomos/pagination/pagination'
import { getInvoices } from '../../domain/usecase/invoices/get-invoices'
import { getInvoicesByNumber } from '../../domain/usecase/invoices/get-invoices-by-number'
import { createCreditNote } from '../../domain/usecase/notascredito/create-notacredito'
import { BsEye } from 'react-icons/bs'


function Invoice(props) {

    const invoiceRepository = new InvoiceRepositoryImp();

    const tabs = [
        { title: "Facturas", eventKey: "invoices" },
        { title: "Notas Credito", eventKey: "notas_credito" },
        { title: "Configuracion", eventKey: "settings" }
    ]
    const [tab, setTab] = useState("invoices")
    const [invoices, SetInvoices] = useState({"data": []})
    const [notasCredito, SetNotasCredito] = useState({"data": []})
    const [isFeatureEnable, SetIsEnable] = useState(true)
    const [number, SetNumber] = useState(undefined)
    const [prefix, SetPrefix] = useState(undefined)
    const [disableCreateCreditNote, SetDisableCreateCreditNote] = useState(true)
    const [invoiceActivePage, SetInvoiceActivePage] = useState(1)
    const [creditNotesActivePage, SetCreditNotesActivePage] = useState(1)

    const perPage = 5

    useEffect(() => {
        window.addEventListener('TabLink-invoice', handleTabEvent);
        window.addEventListener('btn-invoice-show-pdf', handleBtnEvent);
        window.addEventListener('btn-credit-note-show-pdf', handleCreditNotePreview);

        return () => {
            window.removeEventListener('TabLink-invoice', handleTabEvent);
            window.removeEventListener('btn-invoice-show-pdf', handleBtnEvent);
            window.removeEventListener('btn-credit-note-show-pdf', handleCreditNotePreview);
        };
    }, []);

    useEffect(() => {
        getInvoices(1, perPage, SetInvoices)
        const isEnableSuscription = invoiceRepository.isEnable(userSingleton.uid, userSingleton.cid).subscribe(
            (data) => {
                if ("electronic_invoice" in data && data["electronic_invoice"] == 1) {
                    SetIsEnable(true)
                }
                else {
                    SetIsEnable(false)
                }
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
            }
        );
        return () => {
            isEnableSuscription.unsubscribe()
        };
    }, [])

    useEffect(() => {
        if (tab === "notas_credito") {
            SetNotasCredito({"data": []})
            getNotasCredito(1, perPage, SetNotasCredito)
        }
        if (tab === "invoices") {
            SetInvoices({"data": []})
            getInvoices(1, perPage, SetInvoices)
        }
    }, [tab])

    function handleTabEvent(event) {
        SetDisableCreateCreditNote(true)
        setTab(event.detail.eventKey)
    }

    function handleCreditNotePreview(event) {
        dispatchCustomEvent('loading-btn-credit-note-show-pdf')
        const qrArray = event.detail.data.QRStr.split('\n');
        window.open(qrArray[qrArray.length - 1]);
    }

    function handleBtnEvent(event) {
        const pdfSuscription = invoiceRepository.getPdf(userSingleton.uid, userSingleton.cid, event.detail.data.cude).subscribe(
            (data) => {
                const blob = createPdfBlob(data["data"]);
                const pdfUrl = createPdfUrl(blob);
                dispatchCustomEvent('loading-btn-invoice-show-pdf')
                window.open(pdfUrl)
            },
            (error) => {
                console.error('Error fetching invoiceInfoSuscription:', error);
                dispatchCustomEvent('loading-btn-invoice-show-pdf')
            }
        );
        return () => pdfSuscription.unsubscribe()
    }

    function enableCreateCreditNote(data) {
        if (data.data.length > 0) {
            data.data.map((invoice) => {
                if (invoice["state"] === "Emitted") {
                    SetDisableCreateCreditNote(false)
                }
            })
        }
    }

    function handleSearchInvoice() {
        SetInvoices({"data": []})
        getInvoicesByNumber(number, prefix, SetInvoices, enableCreateCreditNote)
    }

    function handleCreateCreditNote() {
        createCreditNote(prefix, number)
    }

    function handleChange(value, func) {
        SetDisableCreateCreditNote(true)
        if (value === "") {
            if (invoices.data.length == 1){
                SetInvoices({"data": []})
                getInvoices(invoiceActivePage, perPage, SetInvoices)
            }
        } else {
            func(value)
        }
    }

    return (
        <GeneralLayout>
            <div>
                <ButtonGroup />
                <br></br>
                <Tab tabKey="invoice" tabs={tabs} />
                {
                    tab === "invoices" && (
                        <div className='invoice-tab-container'>
                            <div className='search-invoice'>
                                <SearchBar
                                    placeholder="Agrega consecutivo de factura"
                                    width='40%'
                                    onChange={(e) => handleChange(e.target.value, SetPrefix)}
                                />
                                <SearchBar
                                    placeholder="Agrega numero de factura"
                                    width='40%'
                                    onChange={(e) => handleChange(e.target.value, SetNumber)}
                                />
                                <ButtonBasic onClick={handleSearchInvoice}>Buscar</ButtonBasic>
                                <ButtonBasic
                                    loadingColor="rgba(0, 170, 170, 1)"
                                    showLoading={true}
                                    disabled={disableCreateCreditNote}
                                    onClick={handleCreateCreditNote}
                                    id="create-credit-note"
                                >
                                    Crear Nota
                                </ButtonBasic>
                            </div>
                            <PaginationPos
                                id="invoices"
                                itemsCountPerPage={perPage}
                                totalItemsCount={invoices ? invoices["totalDocuments"] : 0}
                                getFunction={getInvoices}
                                setFunction={SetInvoices}
                                setActive={SetInvoiceActivePage}
                            ></PaginationPos>
                            <Table
                                onReload={() => [SetInvoices({"data": []}), getInvoices(1, perPage, SetInvoices)]}
                                maxHeight="40vh"
                                headersList={[
                                    "Cliente",
                                    "Estado",
                                    "Fecha",
                                    "Email Status",
                                    "Consecutivo",
                                    "Valor",
                                    "Acciones"
                                ]}
                                data={invoices ? invoices["data"] : []}
                                excludeList={["cude", "number", "time", "prefix"]}
                            >
                                <ButtonAction enableLoadingAnimation={true} btnKey="invoice-show-pdf"><BsEye></BsEye></ButtonAction>
                            </Table>

                        </div>
                    )
                }
                {
                    tab === "notas_credito" && (
                        <div className='invoice-tab-container'>
                            <PaginationPos
                                id="notasCredito"
                                itemsCountPerPage={perPage}
                                totalItemsCount={notasCredito ? notasCredito["totalDocuments"] : 0}
                                getFunction={getNotasCredito}
                                setFunction={SetNotasCredito}
                                setActive={SetCreditNotesActivePage}
                            ></PaginationPos>
                            <Table
                                onReload={() => [SetNotasCredito({"data": []}),getNotasCredito(1, perPage, SetNotasCredito)]}
                                maxHeight="70vh"
                                headersList={[
                                    "Consecutivo",
                                    "Nombre",
                                    "Email",
                                    "Identificación",
                                    "Entrega de Email",
                                    "Estado",
                                    "Número",
                                    "Acciones"
                                ]}
                                data={notasCredito ? notasCredito["data"] : []}
                                excludeList={["uuid", "cude", "sendmail", "preview", "QRStr"]}
                            >
                                <ButtonAction enableLoadingAnimation={true} btnKey="credit-note-show-pdf"><BsEye></BsEye></ButtonAction>
                            </Table>
                        </div>
                    )
                }
                {
                    tab === "settings" && isFeatureEnable && (
                        <InvoiceDataForm></InvoiceDataForm>
                    )
                }
                {
                    tab === "settings" && !isFeatureEnable && (
                        <div class="container" style={{ position: "relative", marginTop: "30%" }}>
                            <p>Para activar  esta funcionalidad, por favor contacte con un asesor y solicite la activacion.</p>
                            <ButtonAction onClick={() => enviarMensaje()}>
                                Activar factura electronica
                            </ButtonAction>
                        </div>
                    )
                }
            </div>
        </GeneralLayout>
    )

}

export default Invoice
