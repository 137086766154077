export function lang(iso, key) {
  var lang = {
    "es": {
      "debit_card": "Tarjeta debito",
      "nequi": "Nequi",
      "otros": "Otros",
      "credit_card": "Tarjeta credito",
      "cash": "Efectivo",
      "credit": "Credito",
      "bill_id": "Id",
      "buy_date": "Fecha Compra",
      "vencimiento": "Fecha Vencimiento",
      "total_pay": "Total a pagar",
        "auth/wrong-password": "La contraseña es inválida o el usuario no tiene una contraseña.",
        "auth/claims-too-large": "La carga útil de los claims proporcionados a setCustomUserClaims() excede el tamaño máximo permitido de 1000 bytes.",
        "auth/email-already-exists": "El correo electrónico proporcionado ya está en uso por un usuario existente. Cada usuario debe tener un correo electrónico único.",
        "auth/id-token-expired": "El token de ID de Firebase proporcionado ha expirado.",
        "auth/id-token-revoked": "El token de ID de Firebase ha sido revocado.",
        "auth/insufficient-permission": "La credencial utilizada para inicializar el SDK de administrador no tiene permisos suficientes para acceder al recurso de autenticación solicitado. Consulte Configurar un proyecto de Firebase para la documentación sobre cómo generar una credencial con permisos adecuados y usarla para autenticar los SDKs de administrador.",
        "auth/invalid-argument": "Se proporcionó un argumento inválido a un método de autenticación. El mensaje de error debería contener información adicional.",
        "auth/invalid-claims": "Los atributos de claims personalizados proporcionados a setCustomUserClaims() son inválidos.",
        "auth/invalid-creation-time": "La hora de creación debe ser una cadena de fecha UTC válida.",
        "auth/invalid-disabled-field": "El valor proporcionado para la propiedad de usuario deshabilitado es inválido. Debe ser un booleano.",
        "auth/invalid-display-name": "El valor proporcionado para la propiedad displayName del usuario es inválido. Debe ser una cadena no vacía.",
        "auth/invalid-email-verified": "El valor proporcionado para la propiedad emailVerified del usuario es inválido. Debe ser un booleano.",
        "auth/invalid-hash-algorithm": "El algoritmo hash debe coincidir con uno de los strings en la lista de algoritmos compatibles.",
        "auth/invalid-hash-block-size": "El tamaño del bloque hash debe ser un número válido.",
        "auth/invalid-hash-derived-key-length": "La longitud de la clave derivada del hash debe ser un número válido.",
        "auth/invalid-hash-key": "La clave hash debe ser un buffer de bytes válido.",
        "auth/invalid-hash-memory-cost": "El costo de memoria del hash debe ser un número válido.",
        "auth/invalid-hash-parallelization": "La paralelización del hash debe ser un número válido.",
        "auth/invalid-hash-rounds": "Las rondas del hash deben ser un número válido.",
        "auth/invalid-hash-salt-separator": "El campo del separador de sal del algoritmo hash debe ser un buffer de bytes válido.",
        "auth/invalid-id-token": "El token de ID proporcionado no es un token de ID de Firebase válido.",
        "auth/invalid-last-sign-in-time": "La última hora de inicio de sesión debe ser una cadena de fecha UTC válida.",
        "auth/invalid-page-token": "El token de página siguiente proporcionado en listUsers() es inválido. Debe ser una cadena no vacía válida.",
        "auth/invalid-password": "El valor proporcionado para la propiedad de contraseña del usuario es inválido. Debe ser una cadena con al menos seis caracteres.",
        "auth/invalid-password-hash": "El hash de la contraseña debe ser un buffer de bytes válido.",
        "auth/invalid-password-salt": "La sal de la contraseña debe ser un buffer de bytes válido.",
        "auth/invalid-photo-url": "El valor proporcionado para la propiedad photoURL del usuario es inválido. Debe ser una URL en formato de cadena.",
        "auth/invalid-provider-data": "El providerData debe ser un array válido de objetos UserInfo.",
        "auth/invalid-oauth-responsetype": "Solo exactamente un OAuth responseType debe estar configurado en true.",
        "auth/invalid-session-cookie-duration": "La duración de la cookie de sesión debe ser un número válido en milisegundos entre 5 minutos y 2 semanas.",
        "auth/invalid-uid": "El uid proporcionado debe ser una cadena no vacía con un máximo de 128 caracteres.",
        "auth/invalid-user-import": "El registro de usuario a importar es inválido.",
        "auth/maximum-user-count-exceeded": "Se ha excedido el número máximo permitido de usuarios a importar.",
        "auth/missing-hash-algorithm": "La importación de usuarios con hashes de contraseña requiere que se proporcione el algoritmo de hash y sus parámetros.",
        "auth/missing-oauth-client-secret": "Se requiere el secreto del cliente de la configuración de OAuth para habilitar el flujo de código OIDC.",
        "auth/phone-number-already-exists": "El número de teléfono proporcionado ya está en uso por un usuario existente. Cada usuario debe tener un número de teléfono único.",
        "auth/project-not-found": "No se encontró un proyecto de Firebase para la credencial utilizada para inicializar los SDKs de administrador. Consulte Configurar un proyecto de Firebase para la documentación sobre cómo generar una credencial para su proyecto y usarla para autenticar los SDKs de administrador.",
        "auth/reserved-claims": "Uno o más claims personalizados proporcionados a setCustomUserClaims() están reservados. Por ejemplo, claims específicos de OIDC como (sub, iat, iss, exp, aud, auth_time, etc.) no deben utilizarse como claves para claims personalizados.",
        "auth/session-cookie-expired": "La cookie de sesión de Firebase proporcionada ha expirado.",
        "auth/session-cookie-revoked": "La cookie de sesión de Firebase ha sido revocada.",
        "auth/uid-already-exists": "El uid proporcionado ya está en uso por un usuario existente. Cada usuario debe tener un uid único.",
        "auth/admin-restricted-operation": "Esta operación está restringida solo a administradores.",
        "auth/app-not-authorized": "Esta aplicación, identificada por el dominio donde está alojada, no está autorizada para usar la autenticación de Firebase con la clave API proporcionada. Revise la configuración de su clave en la consola de Google API.",
        "auth/app-not-installed": "La aplicación móvil solicitada correspondiente al identificador (nombre del paquete de Android o ID de paquete de iOS) proporcionado no está instalada en este dispositivo.",
        "auth/captcha-check-failed": "El token de respuesta de reCAPTCHA proporcionado es inválido, ha expirado, ya se ha utilizado o el dominio asociado con él no coincide con la lista de dominios permitidos.",
        "auth/code-expired": "El código de SMS ha expirado. Por favor, reenvíe el código de verificación para intentarlo de nuevo.",
        "auth/cordova-not-ready": "El framework Cordova no está listo.",
        "auth/cors-unsupported": "Este navegador no es compatible.",
        "auth/credential-already-in-use": "Esta credencial ya está asociada a una cuenta de usuario diferente.",
        "auth/custom-token-mismatch": "El token personalizado corresponde a una audiencia diferente.",
        "auth/requires-recent-login": "Esta operación es sensible y requiere autenticación reciente. Inicie sesión nuevamente antes de intentar esta solicitud.",
        "auth/dependent-sdk-initialized-before-auth": "Otro SDK de Firebase se inicializó y está intentando usar Auth antes de que Auth esté inicializado. Asegúrese de llamar a `initializeAuth` o `getAuth` antes de iniciar cualquier otro SDK de Firebase.",
        "auth/dynamic-link-not-activated": "Por favor, active Dynamic Links en la consola de Firebase y acepte los términos y condiciones.",
        "auth/email-change-needs-verification": "Los usuarios con múltiples factores siempre deben tener un correo electrónico verificado.",
        "auth/email-already-in-use": "La dirección de correo electrónico ya está en uso por otra cuenta.",
        "auth/emulator-config-failed": "La instancia de Auth ya se ha utilizado para hacer una llamada a la red. Auth ya no se puede configurar para usar el emulador. Intente llamar a 'connectAuthEmulator()' antes.",
        "auth/expired-action-code": "El código de acción ha expirado.",
        "auth/cancelled-popup-request": "Esta operación ha sido cancelada debido a que se abrió otra ventana emergente en conflicto.",
        "auth/internal-error": "Se ha producido un error interno de autenticación.",
        "auth/invalid-app-credential": "La solicitud de verificación del teléfono contiene un verificador de aplicación inválido. El token de respuesta de reCAPTCHA es inválido o ha expirado.",
        "auth/invalid-app-id": "El identificador de la aplicación móvil no está registrado para el proyecto actual.",
        "auth/invalid-user-token": "La credencial de este usuario no es válida para este proyecto. Esto puede suceder si el token del usuario ha sido manipulado o si el usuario no pertenece al proyecto asociado con esta clave API.",
        "auth/invalid-auth-event": "Se ha producido un error interno de autenticación.",
        "auth/invalid-verification-code": "El código de verificación de SMS utilizado para crear la credencial de autenticación telefónica es inválido. Por favor, reenvíe el código de verificación y asegúrese de utilizar el código de verificación proporcionado por el usuario.",
        "auth/invalid-continue-uri": "La URL de continuación proporcionada en la solicitud es inválida.",
        "auth/invalid-cordova-configuration": "Hay algo incorrecto en su archivo de configuración de Cordova. Verifique que esté utilizando el plugin de Firebase Auth, así como el archivo google-services.json o GoogleService-Info.plist.",
        "auth/invalid-custom-token": "El formato del token personalizado es incorrecto. Revise la documentación para saber cómo debe estructurarse el token.",
        "auth/invalid-dynamic-link-domain": "El dominio de enlace dinámico proporcionado no está configurado o no se encuentra autorizado para su proyecto.",
        "auth/invalid-email": "La dirección de correo electrónico no es válida.",
        "auth/invalid-emulator-scheme": "La URL del emulador proporcionada es inválida. La URL debe ser de esquema HTTP o HTTPS.",
        "auth/invalid-api-key": "La clave API proporcionada es inválida o se ha deshabilitado en el proyecto de Firebase.",
        "auth/invalid-cert-hash": "El hash de certificado proporcionado es inválido.",
        "auth/invalid-credential": "La credencial de autenticación proporcionada no es válida. Revise el método de autenticación y el mensaje de error adicional para obtener más detalles.",
        "auth/invalid-message-payload": "La carga útil del mensaje proporcionado es inválida. Debe ser una cadena de no más de 1.024 caracteres.",
        "auth/invalid-multi-factor-session": "La solicitud no contiene una verificación MFA válida. Puede deberse a que el código haya expirado o que no se haya solicitado ningún desafío MFA.",
        "auth/invalid-oauth-provider": "La configuración de proveedor OAuth proporcionada es inválida. Asegúrese de que el proveedor esté habilitado en la consola de Firebase.",
        "auth/invalid-oauth-token": "El token OAuth proporcionado es inválido o ha expirado.",
        "auth/invalid-oauth-verifier": "El verificador OAuth proporcionado es inválido.",
        "auth/invalid-password-reset-code": "El código de restablecimiento de contraseña es inválido o ha expirado.",
        "auth/invalid-persistence-type": "El tipo de persistencia especificado es inválido. Los valores permitidos son 'local', 'session' y 'none'.",
        "auth/invalid-provider-id": "El identificador de proveedor especificado es inválido.",
        "auth/invalid-recipient-email": "Es necesario proporcionar un correo electrónico de destinatario válido.",
        "auth/invalid-sender": "Es necesario proporcionar un correo electrónico de remitente válido.",
        "auth/invalid-verification-id": "El ID de verificación utilizado para crear la credencial de autenticación telefónica es inválido.",
        "auth/invalid-tenant-id": "El ID de inquilino proporcionado es inválido.",
        "auth/mfa-info-not-found": "La información de segundo factor proporcionada no se encuentra en el usuario.",
        "auth/missing-android-pkg-name": "Es necesario proporcionar un nombre de paquete Android válido si el enlace de la app se usa en un dispositivo Android.",
        "auth/missing-continue-uri": "Es necesario proporcionar una URL de continuación válida en la solicitud.",
        "auth/missing-iframe-start": "Se ha producido un error interno de autenticación.",
        "auth/missing-ios-bundle-id": "Es necesario proporcionar un ID de paquete iOS válido si el enlace de la app se usa en un dispositivo iOS.",
        "auth/missing-multi-factor-info": "Es necesario proporcionar la información de segundo factor para completar la inscripción de MFA.",
        "auth/missing-multi-factor-session": "La solicitud no contiene una verificación MFA válida. Puede deberse a que el código haya expirado o que no se haya solicitado ningún desafío MFA.",
        "auth/missing-phone-number": "Es necesario proporcionar un número de teléfono válido.",
        "auth/missing-verification-code": "Es necesario proporcionar un código de verificación para crear la credencial de autenticación telefónica.",
        "auth/missing-verification-id": "Es necesario proporcionar un ID de verificación para crear la credencial de autenticación telefónica.",
        "auth/missing-uid": "Es necesario proporcionar un UID para completar la solicitud.",
        "auth/operation-not-allowed": "El proveedor de autenticación especificado no está habilitado en la consola de Firebase.",
        "auth/popup-blocked": "Se ha bloqueado la ventana emergente debido a que el navegador la ha bloqueado. Asegúrese de que las ventanas emergentes estén habilitadas en su navegador.",
        "auth/popup-closed-by-user": "El usuario ha cerrado la ventana emergente antes de completar la operación.",
        "auth/provider-already-linked": "El proveedor ya está vinculado a la cuenta del usuario. Cada proveedor solo puede estar vinculado a una cuenta.",
        "auth/quota-exceeded": "La cuota de solicitud ha sido excedida. Intente de nuevo más tarde.",
        "auth/redirect-cancelled-by-user": "El usuario canceló la operación de redirección antes de completarla.",
        "auth/redirect-operation-pending": "Una operación de redirección de autenticación ya está en progreso.",
        "auth/rejected-credential": "La solicitud contiene una credencial de autenticación rechazada.",
        "auth/second-factor-already-in-use": "El segundo factor ya está inscrito en el usuario.",
        "auth/tenant-id-mismatch": "El ID de inquilino proporcionado no coincide con el ID del inquilino actual.",
        "auth/timeout": "La operación ha excedido el tiempo de espera permitido. Intente nuevamente.",
        "auth/user-token-expired": "El token de usuario ha expirado. Inicie sesión nuevamente.",
        "auth/too-many-requests": "Se han realizado demasiadas solicitudes en un corto período de tiempo. Espere antes de intentarlo de nuevo.",
        "auth/unauthorized-continue-uri": "El dominio de la URL de continuación no está autorizado para el proyecto actual.",
        "auth/unsupported-persistence-type": "El tipo de persistencia especificado no es compatible con el entorno actual.",
        "auth/unsupported-tenant-operation": "Esta operación no está soportada en la consola de Firebase para proyectos con inquilinos.",
        "auth/unverified-email": "El correo electrónico no está verificado.",
        "auth/user-cancelled": "El usuario canceló la operación.",
        "auth/user-disabled": "La cuenta del usuario ha sido deshabilitada por un administrador.",
        "auth/user-mismatch": "La credencial proporcionada no corresponde con el usuario autenticado previamente.",
        "auth/user-not-found": "No existe ningún usuario registrado con este identificador.",
        "auth/user-signed-out": "El usuario ha cerrado la sesión.",
        "auth/weak-password": "La contraseña es demasiado débil. Elija una contraseña más fuerte.",
        "auth/web-storage-unsupported": "El navegador no admite almacenamiento web necesario para ejecutar la operación."
    }
  }

  if (Object.prototype.hasOwnProperty.call(lang, iso) && Object.prototype.hasOwnProperty.call(lang[iso], key)) {
    return lang[iso][key];
  }
  return key;
}
