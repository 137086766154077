import { TaxRepository } from '../../domain/models/taxes/gateway/taxes-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';
import { Tax } from '../../domain/models/taxes/taxes';

export default class TaxRepositoryImp extends TaxRepository {

  private taxes : any = {
    "sell": [],
    "buy": [],
    "retention": [],
    "all": []
  };

  get(uid: string, cid: string | null, _type : string): Observable<Tax[]> {
    const httpClient = new HttpClient(uid, cid);
    if(this.taxes[_type].length > 0){
      return new Observable(observer => {
        observer.next(this.taxes[_type]);
        observer.complete();
      });
    }
    const res = httpClient.get<Tax[]>(`tax?type=${_type}`);
    res.subscribe(taxes => {
      this.taxes[_type] = taxes;
    });
    return res;
  }
}
