//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import { VscDebugContinueSmall } from 'react-icons/vsc';
import { AiFillFacebook, AiFillInstagram, AiFillTwitterCircle, AiOutlineCheck } from 'react-icons/ai';
import { ShoppingCart, FileText, BarChart2, CreditCard, Zap, Lock, Smartphone, Star } from "lucide-react"

//Controller import
import { useEffect, useState } from "react";
import { getPlans } from "../../domain/usecase/suscriptions/get-all-plans";

function Landing(props) {

    const [plans, SetPlans] = useState([])
    const caracteristicas = [
        {
            icon: <ShoppingCart />,
            title: "Gestión de Ventas",
            description: "Procesa ventas rápidamente con una interfaz intuitiva. Compatible con múltiples métodos de pago.",
        },
        {
            icon: <FileText />,
            title: "Control de Inventario",
            description: "Mantén un seguimiento preciso de tu stock en tiempo real. Recibe alertas de reabastecimiento automáticas.",
        },
        {
            icon: <BarChart2 />,
            title: "Análisis de Datos",
            description: "Obtén insights valiosos con reportes detallados sobre ventas, productos más vendidos y tendencias de clientes.",
        },
        /* {
          icon: <CreditCard />,
          title: "Pagos Integrados",
          description: "Acepta pagos con tarjeta, efectivo y métodos digitales. Integración segura con principales proveedores.",
        }, */
        {
            icon: <Smartphone />,
            title: "Acceso Móvil",
            description: "Gestiona tu negocio desde cualquier lugar con nuestra app móvil para iOS y Android.",
        },
        {
            icon: <Zap />,
            title: "Integración Rápida",
            description: "Configura tu tienda en minutos. Importa fácilmente tu catálogo de productos y datos de clientes.",
        }
    ]

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname == "/") {
            getPlans(SetPlans)
        }
    }, [])

    return (
        <div style={{ overflow: "hidden" }}>
            <header>
                <h1># Easypos</h1>
                <div className="land-btn-group">
                    <Button className="land-start-btn-ligth head" href="/login">Iniciar sesion</Button>
                    <Button  className="land-start-btn head register" href="/register">Registrarse</Button>
                </div>
            </header>

            <div className="land-g1">
                <h2>Revoluciona tu Negocio</h2>
                <p>Gestiona ventas, inventario y clientes en una sola plataforma. Simplifica tu operación y potencia tu crecimiento.</p>
                <Empezar class="land-start-btn-ligth" text="Comenzar gratis" />
            </div>

            <div className="land-g2" >
                <h2 id='features'>Características Principales</h2>
                <div className="container-box">
                    {
                        caracteristicas.map((caracteristica) => {
                            return (
                                <div className="land-pagos">
                                    <div>
                                        <p className="land-plan-icon">{caracteristica.icon}</p>
                                        <p className="land-plan-title">{caracteristica.title}</p>
                                        <p className="land-plan-description">{caracteristica.description}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>

            <div className="land-g3">
                <h2>Diseñado para la Facilidad de Uso</h2>
                <p>Easypos está diseñado pensando en ti. Nuestra interfaz intuitiva permite que tú y tu equipo comiencen a vender en cuestión de minutos, sin necesidad de largas capacitaciones.</p>
                <div className='land-g3-box'>
                    <div className='group'>
                        <p><AiOutlineCheck /> Interfaz intuitiva y fácil de navegar</p>
                        <p><AiOutlineCheck /> Capacitación mínima requerida</p>
                        <p><AiOutlineCheck /> Soporte 24/7 disponible</p>
                        <p><AiOutlineCheck /> Actualizaciones regulares basadas en feedback de usuarios</p>
                        <Empezar text="Comenzar gratis" />
                    </div>
                    <img src="https://public-pos.s3.us-west-2.amazonaws.com/tmp/Untitled_Artwork+3.png"></img>
                </div>
            </div>

            <div className="land-g2">
                <h2 id="pricing">Planes y Precios</h2>
                <div className="container-box">
                    {
                        plans ?
                            plans?.map((plan) => {
                                return (
                                    <div className="land-pagos" style={{ lineHeight: "20px" }}>
                                        <p className="land-plan-title" style={{ fontSize: "30px", paddingLeft: "20px" }}>{plan.name?.charAt(0).toUpperCase() + plan.name?.slice(1)}</p>
                                        <p className="land-plan-description" style={{ fontSize: "25px", fontWeight: "bold", paddingLeft: "20px" }}>{plan?.price > 0 && (plan?.price + " COP / mes")}</p>
                                        <p className="land-plan-description" style={{ fontSize: "25px", fontWeight: "bold", paddingLeft: "20px" }}>{plan?.price == 0 && ("Gratis")}</p>
                                        <div style={{ height: "110px", margin: "20px" }}>
                                            {
                                                plan.information.data?.map((item) => {
                                                    return (
                                                        <p className="land-plan-item"> <AiOutlineCheck /> {item}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                        <Empezar msg="Quiero una demo"></Empezar>
                                    </div>
                                )
                            }) : <div></div>
                    }
                </div>

                <p>** Con limite de clientes, ventas y productos</p>
            </div>

            <div className="land-g1">
                <h2>¿Listo para Potenciar tu Negocio?</h2>
                <p>Únete a otras empresas que ya están usando Easypos para simplificar su gestión y aumentar sus ventas.</p>
                <Empezar class="land-start-btn-ligth" text="Comenzar gratis" />
            </div>

            <footer>
                <div className="information">
                    <div className='company'>
                        <div>
                            <span># Easypos</span>
                        </div>
                        <p>Simplificando la gestión empresarial desde 2020</p>
                    </div>
                    <div className="lists">
                        {[
                            {
                                title: "Producto",
                                links: [
                                    { name: "Características", href: "#features" },
                                    { name: "Precios", href: "#pricing" },
                                    { name: "Comienza", href: "/register" }
                                ]

                            },
                            /* {
                                title: "Compañía",
                                links: [
                                    { name: "Acerca de", href: "#" },
                                    { name: "Blog", href: "#" },
                                    { name: "Contacto", href: "#" }
                                ]
                            }, */
                            /* {
                                title: "Legal",
                                links: [
                                    { name: "Términos", href: "#" },
                                    { name: "Privacidad", href: "#" }
                                ]
                            } */
                        ].map((section, index) => (
                            <div className='group' key={index}>
                                <h4>{section.title}</h4>
                                <ul>
                                    {section.links.map((link, i) => (
                                        <li key={i}>
                                            <a style={{color:"black"}} href={link.href}>
                                                {link.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="networks">
                        <p style={{ fontSize:"15px", margin:"3px", marginTop:"12px"}}>© 2020 Easypos. Todos los derechos reservados.</p>
                        <div style={{display:"flex"}}>
                            {[
                                {  icon: <AiFillTwitterCircle /> },
                                {  icon: <AiFillFacebook /> },
                                { icon: <AiFillInstagram /> }
                            ].map((social, index) => (
                                <p style={{ fontSize:"25px", margin:"3px", marginBottom:"15px"}}>{social.icon}</p>
                            ))}
                        </div>
                    </div>
            </footer>
        </div>
    )

}

function Empezar(props) {
    return (
        <div>
            <Button style={props.style ? props.style : {}} onClick={() => [window.location.replace("register")]} className={props.class ? props.class : "land-start-btn"} >{props.text ? props.text : "Comenzar"}</Button>
        </div>
    )
}

export default Landing
