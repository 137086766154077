import { dispatchCustomEvent } from '../../../utils/dispatch';
import ModalBtn from '../../../moleculas/modalButton/hub/view';

import ThirdsTable from '../thirdsTable/view';

function ThirdsModal(props) {

    function update() {
        dispatchCustomEvent('update-thirds', {})
    }

    return (
        <ModalBtn
            id={props.id ? "modal-third-"+props.id : "modal-third"}
            title={props.text ? props.text : "Clientes"}
            onClick={update}
            width="95%"
        >
            <ThirdsTable id={props.id ? "modal-third-"+props.id : "modal-third"} forceLoadData={true} type={props.type ? props.type : "client"}></ThirdsTable>
        </ModalBtn>
    )
}

export default ThirdsModal
