import ModalBtn from '../../../moleculas/modalButton/hub/view';
import Form from 'react-bootstrap/Form';
import { BiEdit, BiShow } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import './style.css'
import { getThirdsById } from '../../../domain/usecase/thirds/getById';

const ShowThird = ({data, id}) => {

    const [third, SetThird] = useState({})

    useEffect(() => {third}, [third])

    function update() {
        getThirdsById(SetThird, data.third_id)
    }

    return (
        <div>
            <ModalBtn
                id={id}
                title={<BiShow/>}
                type="action"
                onClick={update}
            >
                <div className='inventario-box' style={{ marginTop: "15px" }}>
                    <Form action="javascript:void(0);" id="third-form">
                        <Form.Group >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control disabled={true} placeholder={third.name} id="third-name"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Email</Form.Label>
                            <Form.Control disabled={true} placeholder={third.email} id="third-email"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Telefono</Form.Label>
                            <Form.Control disabled={true} placeholder={third.phone} id="third-phone"></Form.Control>
                        </Form.Group>
                    </Form>
                </div>
            </ModalBtn>
        </div>
    )
}

export default ShowThird
