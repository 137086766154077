// import './style.css'
import { Badge, Button } from 'react-bootstrap'
import { dispatchCustomEvent } from '../../../utils/dispatch';
import "./style.css"
import { useEffect, useState } from 'react';
import LoadingBtn from '../../loading/loadingBtn/view';

function ButtonBasic(props) {

    const [showloading, setShowLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [badge, setBadge] = useState(0)

    useEffect(() => {
        window.addEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent);
        return () => {
            window.removeEventListener(props.id ? 'loading-btn-'+props.id : 'loading-btn', handleEvent);
        };
    }, [])

    useEffect(() => {setBadge(props.badge ? props.badge : 0)}, [props.badge])

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            event.preventDefault();  // Evitar el comportamiento por defecto
        }
    }

    function handleEvent() {
        setLoading(false)
    }

    function handleClick(){
        setLoading(true)
        dispatchCustomEvent('modal'+props.modalID,[])
        if(props.onClick?props.onClick:false) {props.onClick()}
        if(props.showLoading ? props.showLoading : false) {setShowLoading(props.showLoading)}
    }

    return (
        <Button
            style={props.style?props.style:{}}
            id={props.id ? "modal-btn-basic-" + props.id : "modal-btn-basic"}
            aria-disabled={true}
            disabled={props.disabled ? props.disabled : false}
            className={props.type == "topbar" ? ('buttonTopBar') : ('buttonBasic')}
            onClick={handleClick}
            type={props.type ? props.type : "button"}
            hidden={props.hidden ? props.hidden : false}
            onKeyDown={handleKeyDown}
        >
            <LoadingBtn loading={loading && showloading} color={props.loadingColor ? props.loadingColor : "white"}>
                {props.children}
                {props.icon}
                {props.title}
                {
                    badge > 0 && (<Badge style={{marginLeft:"10px"}} bg="danger">{badge}</Badge>)
                }
            </LoadingBtn>
        </Button>
    )

}

export default ButtonBasic
