import { userSingleton } from "../../../classes/User";
import NotasCreditoRepository from "../../../infrastructure/notascredito/notascredito-repository-implementation";
import { dispatchCustomEvent } from "../../../utils/dispatch";

const creditNoteRepo = new NotasCreditoRepository();

function createCreditNote(prefix, number) {
    var data = {
        "model": "credit_note",
        "prefix": prefix,
        "number": number
    }
    const suscription = creditNoteRepo.create(userSingleton.uid, userSingleton.cid, data).subscribe(
        (data) => {
            dispatchCustomEvent('loading-btn-create-credit-note', {})
            if (data.responseSQS === 200){
                dispatchCustomEvent("notification-popup", {message: "Nota crédito enviada para procesar"})
            }
        },
        (error) => {
            dispatchCustomEvent('loading-btn-create-credit-note', {})
            if (error.response.status == 429) {
                dispatchCustomEvent("notification-popup", {message: "Alcanzaste el límite de notas credito", type:"error"})
            }
        }
    )
    return () => suscription.unsubscribe()
}

export { createCreditNote }
