//Custo css import
import './style.css'

//Boostrap import
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { userSingleton } from '../../../classes/User';
import { lang } from '../../../utils/langs';

import { handleSubTotal } from "./controller"
import ModalBtn from '../../../moleculas/modalButton/hub/view';
import DatePicker from "react-datepicker";
import { dispatchCustomEvent } from '../../../utils/dispatch';
import { getIsInvoiceEnable } from '../../../domain/usecase/invoices/is-enable';
import { getTotal, getCar, clearCar } from '../../../domain/usecase/carrito/carrito';
import { createSell } from '../../../domain/usecase/sells/sell';
import { createInvoice } from '../../../domain/usecase/invoices/create-invoice';
import { createBuy } from '../../../domain/usecase/buys/buys';
import { ButtonAction, ButtonBasic } from '../../../atomos/buttons';
import { MdDelete } from 'react-icons/md';
import { getTaxes } from '../../../domain/usecase/tax/get-taxes-taxes';

function PayMethods(props) {

    const [totalToPay, SetTotalToPay] = useState(0)
    const [buyDate, setBuyDate] = useState(new Date());
    const [paymethods, SetPayMethods] = useState([])
    const [cambio, SetCambio] = useState(0)
    const [isPayDisable, SetIsPayDisable] = useState(true)
    const [generateInvoice, SetGenerateInvoice] = useState(true)
    const [selectedMethods, SetSelectedMethods] = useState([])
    const [retentions, SetRetentions] = useState([])
    const [tercero, SetTercero] = useState(undefined)

    useEffect(() => {
        getIsInvoiceEnable(SetGenerateInvoice)
        window.addEventListener('process-payment', handleProcessPayment);
        window.addEventListener('btn-thirds-add', (e) => {SetTercero(e.detail.data)});
        return () => {
            window.removeEventListener('process-payment', handleProcessPayment);
            window.removeEventListener('btn-thirds-add', (e) => {SetTercero(e.detail.data)});
        };
    }, [])

    useEffect(() => {
        handleFormChage()
    }, [selectedMethods, generateInvoice])

    function handleProcessPayment(event) {
        handleClose()
        if ("data" in event.detail.data.bill && generateInvoice && props.type != "buy") {
            createInvoice(event.detail.data.bill.data)
        }
    }

    function handleRetention() {
        if(retentions.length == 0 && props.type == "buy"){
            getTaxes("retention", SetRetentions)
        }
    }

    async function handlePay(e) {
        e.preventDefault()
        var aux = selectedMethods.map((id) => {
            var elementValue = window.document.getElementById("pay-" + id).value
            if (id == '3' && cambio < 0) {
                elementValue = totalToPay
            }
            return { "id": id, "value": elementValue }
        })
        var data = {}
        if (props.type == "buy") {
            data = {
                "tercero": tercero,
                "expiration": window.document.getElementById("expiration-days")?.value,
                "documento_compra": window.document.getElementById("document-buy")?.value,
                "buy_date": buyDate,
                "retention": window.document.getElementById("retention-compras")?.value,
                "methods": aux,
                "total": totalToPay,
                "car": getCar()
            }
            createBuy(data)
        } else {
            data = {
                "tercero": tercero,
                "methods": aux,
                "generateInvoice": generateInvoice,
                "total": totalToPay,
                "car": getCar()
            }
            createSell(data)
        }
        return 'ok'
    }

    function PrintElem() {
        var toPrint = window.document.getElementById('bill-sell-module')
        var mywindow = window.open('', 'PRINT');
        mywindow.document.write(toPrint.innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.print();
        mywindow.onfocus = function () {
            mywindow.close()
            handleClose()
        }
        return true;
    }

    function handleFormChage() {
        handleRetention()
        userSingleton.payMethods().then((res) => {
            SetPayMethods(res)
        })
        SetTotalToPay(getTotal())
        var handleSub = handleSubTotal(selectedMethods)
        SetCambio(handleSub[0] ? handleSub[0] : 0)
        IsPayDisable()
    }

    function handleClose() {
        clearCar()
        SetCambio(0)
        SetSelectedMethods([])
        SetIsPayDisable(true)
        dispatchCustomEvent('btn-thirds-add', undefined)
        dispatchCustomEvent('close-all-modal', {})
    }

    function IsPayDisable() {
        var aux = 0
        handleSubTotal(selectedMethods).map((i) => {aux = aux+i})
        if  (tercero && cambio >= 0 && selectedMethods.length > 0 && aux <= 0) {
            SetIsPayDisable(false)
        } else {
            SetIsPayDisable(true)
        }
    }


    return (
        <ModalBtn
            id="paymethods"
            title="Pagar"
            onClick={handleFormChage}
        >
            <div className='pay-box-title'>
                <p className="titles-pay">${totalToPay}</p>
                <p className="titles-cambio">Cambio ${cambio * -1}</p>
            </div>

            <div className='child-buy'>
                {props.children}
            </div>

            <div className='pay-box'>
                <div>
                    <p className='lable'>Nombre:</p>
                    <p className='value'>{tercero?.name}</p>
                </div>
                <div>
                    <p className='lable'>identificacion:</p>
                    <p className='value'>{tercero?.identification}</p>
                </div>
                <div>
                    <p className='lable'>Email:</p>
                    <p className='value'>{tercero?.email}</p>
                </div>
                <div  >
                    <p className='lable'>Telefono:</p>
                    <p className='value'>{tercero?.phone}</p>
                </div>

                <div hidden={props.type != "buy"}>
                    <p className='lable'>Retencion:</p>
                    <Form.Select style={{ padding: "5px" }} className='value' id="retention-compras" >
                        <option value={0}>Ninguno</option>
                        {
                            retentions ?
                                retentions?.map((item) => {
                                    return (
                                        <option value={item?.value}>{item?.description}</option>
                                    )
                                }) : <div></div>
                        }
                    </Form.Select>
                </div>

                <div hidden={props.type != "buy"}>
                    <p className='lable'>Fecha de compra</p>
                    <DatePicker style={{ padding: "5px" }} className='value' selected={buyDate} onChange={(date) => [setBuyDate(date)]}></DatePicker>
                </div>

                <div hidden={props.type != "buy"}>
                    <p className='lable labledoc'>Documento:</p>
                    <Form.Control className='value ' id="document-buy" type="text" placeholder="documento compra" />
                </div>

            </div>


            <Form id="paymethod-form" action="javascript:void(0);"
                onSubmit={(data) => [handlePay(data)]}
                onChange={() => [handleFormChage()]}
                className='pay-box'
                style={{ display: "block", marginTop: "10px" }}
            >
                <div>
                    <p className='lable'>Methodos de pago:</p>
                    <Form.Select
                        id="select-pay-methods"
                        onChange={(e) => [paymethods.filter(i => i.id == e.target.value)[0] ? SetSelectedMethods([...selectedMethods, e.target.value]) : ""]}
                    >
                        <option value="---">---</option>
                        {
                            paymethods.map((method) => {
                                return (
                                    <option value={method.id}>{lang("es", method.method)}</option>
                                )
                            })
                        }
                    </Form.Select>
                </div>

                <div style={{ height:"auto", minHeight:"3vh", maxHeight:"12vh", overflowY: "auto", width:"100%" }} name="color" id="color">
                    {
                        selectedMethods ?
                            selectedMethods.map((id) => {
                                return (
                                    <div className='pay-box-methods'>
                                        <div className='group'>
                                            <p className='lable'>{lang("es", paymethods.filter(i => i.id == id)[0].method)}:</p>
                                            <Form.Control className='value ' id={'pay-' + id} type="text" placeholder="valor" />
                                        </div>
                                        {/* {
                                            id == 4 && (
                                                <div >
                                                    <p className='lable'>Vencimiento (dias)</p>
                                                    <Form.Control className='value-alter' id="expiration-days"></Form.Control>
                                                </div>
                                            )
                                        } */}
                                        <div >
                                            <p className='lable'></p>
                                            <ButtonAction onClick={() => [SetSelectedMethods(selectedMethods.filter(i => i != id))]}> <MdDelete></MdDelete> </ButtonAction>
                                        </div>
                                    </div>
                                )
                            }) : <div></div>
                    }
                </div>


                <ButtonBasic
                    id="pay-btn"
                    loadingColor="rgba(0, 170, 170, 1)"
                    style={{ width: "96%", margin: "3%" }}
                    title={props.type === "buy" ? (tercero ? "Comprar" : "Agregue un proveedor") : (tercero ? "Pagar" : "Agregue un cliente")}
                    disabled={isPayDisable}
                    showLoading={true}
                    type="submit"
                ></ButtonBasic>
            </Form>
        </ModalBtn>
    )
}

export default PayMethods
