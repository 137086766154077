import { userSingleton } from "../../../classes/User";
import BuysRepositoryImp from '../../../infrastructure/buys/buys-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const repo = new BuysRepositoryImp();

function getById(comprafunc, payFunc, methodsFunc, id) {
    const suscription = repo.getById(userSingleton.uid, userSingleton.cid, id).subscribe(
        (response) => {
            const parsedData = JSON.parse(response.data);
            if (Object.prototype.hasOwnProperty.call(parsedData, 'payment') && parsedData["payment"].length > 0) {
                payFunc(parsedData["payment"])
            }else{
                payFunc(EmptyArray)
            }

            methodsFunc(parsedData["paymethod"])
            comprafunc(parsedData["data"])
        },
        () => {
            dispatchCustomEvent("notification-popup", {message: "Error consultando compra", type:"error"})
        }

    )
    return () => suscription.unsubscribe()
}

export { getById }
