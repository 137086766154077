import { userSingleton } from "../../../classes/User";
import InsightsRepositoryImp from '../../../infrastructure/insights/insights-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new InsightsRepositoryImp();

function getInsights(insight, period, func) {
    const suscription = repo.get(userSingleton.uid, userSingleton.cid, insight, period).subscribe(
        (data) => {
            /* dispatchCustomEvent("notification-popup", {message: "Compra exitosa"}) */
            func(data)
        },
        (error) => {
            console.log(error)
        }
    )
    return () => suscription.unsubscribe()
}

export { getInsights }
