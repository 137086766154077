import { Invoice, InvoicePDF } from '../../domain/models/invoice/invoice';
import { InvoiceInformation } from '../../domain/models/invoice/invoice-information';
import { NotasCreditoRepository } from '../../domain/models/notascredito/gateway/notascredito-repository';
import { InvoiceResponse } from '../../domain/models/notascredito/notascredito';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class NotasCreditoRepositoryImp extends NotasCreditoRepository {
  getAll(uid: string, cid: string | null, page: number, perPage: number): Observable<InvoiceResponse> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<InvoiceResponse>(`notas/credito?page=${page}&perPage=${perPage}`);
  }

  create(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post<any>('notas/credito', data);
  }
}
