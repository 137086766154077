import React, { useEffect, useState } from 'react';
import './popup.css'; // Asegúrate de crear y estilizar este archivo CSS
import { ButtonBasic } from '../../../atomos/buttons/index';
import { dispatchCustomEvent } from '../../../utils/dispatch';

const ConfirmPopUp = ({eventKey, msg=""}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState("")
    const [data, setData] = useState({})

    useEffect(() => {
        window.addEventListener('btn-notification-confirm-'+eventKey, (e) => [setIsVisible(true), setData(e.detail.data)]);
        window.addEventListener('notification-confirm-'+eventKey, (e) => [setIsVisible(true), setData(e.detail.data)]);
        return () => {
            window.removeEventListener('btn-notification-confirm-'+eventKey, (e) => [setIsVisible(true), setData(e.detail.data)]);
            window.removeEventListener('notification-confirm-'+eventKey, (e) => [setIsVisible(true), setData(e.detail.data)]);
        };
    }, [data]);

    if (!isVisible) return null;

    return (
        <div className={"confirm-overlay"}>
            <div className={"confirm-content "+type}>
                <p className="confirm-message">{msg}</p>
                <div>
                    <ButtonBasic onClick={() => { setIsVisible(false), dispatchCustomEvent( 'loading-btn' , {}) }}>Cancelar</ButtonBasic>
                    <ButtonBasic onClick={(e) => [dispatchCustomEvent( eventKey , data), setIsVisible(false)]}>Aceptar</ButtonBasic>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPopUp;
