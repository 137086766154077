import React, { useState, useEffect } from 'react';
import { dispatchCustomEvent } from '../../utils/dispatch';

const BarcodeScanner = ({id, data}) => {

  const [barcode, setBarcode] = useState('');
  const [scanning, setScanning] = useState(false);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!scanning) {
        setScanning(true);
        setBarcode('');
      }
      if (e.key === 'Enter') {
        setScanning(false);
        var item = findProduct(barcode, data)
        if (item) {
          dispatchCustomEvent('btn-add-product', item);
        }else{
          dispatchCustomEvent('notification-popup', { message: 'Producto no encontrado' });
        }
      } else {
        setBarcode((prev) => prev + e.key);
      }
    };

    window.addEventListener('keypress', handleKeyPress);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [barcode, scanning]);

  function findProduct(input, data) {
      var response = data.filter((item) => {
          var tmp = item.name.toLowerCase().includes(input.toLowerCase())
          if (!tmp) {
              tmp = item.sku.toLowerCase().includes(input.toLowerCase())
          }
          if (!tmp) {
              tmp = item.barcode?.toLowerCase().includes(input.toLowerCase())
          }

          return tmp
      })
      if (response.length == 0) {
          return undefined
      }
      return response[0]
  }

  return (
    <div>
      {/* <ButtonBasic>Scanear</ButtonBasic> */}
    </div>
  );
};

export default BarcodeScanner;
