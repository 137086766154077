//React import
import { useEffect, useState } from 'react';

//Widgets import
import Modal from '../../../atomos/modal/view';
import {ButtonHub, ButtonBasic, ButtonAction} from '../../../atomos/buttons/index';


function ModalBtn(props) {
    return (
        <div hidden={props.hidden ? props.hidden : false}>
            {
                props.type == "basic" || props.type == undefined && (
                    <ButtonBasic
                        id={props.id ? "modal-btn-"+props.id : "modal-btn"}
                        onClick={props.onClick}
                        title={props.title}
                        modalID={props.id}
                        badge={props.badge? props.badge : 0}
                        icon={props.icon ? props.icon : ''}
                    />
                )
            }
            {
                props.type == "topbar" && (
                    <ButtonBasic
                        id={props.id ? "modal-btn-"+props.id : "modal-btn"}
                        onClick={props.onClick}
                        title={props.title}
                        modalID={props.id}
                        type="topbar"
                    />
                )
            }
            {
                props.type == "action" && (
                    <ButtonAction
                        id={props.id ? "modal-btn-"+props.id : "modal-btn"}
                        onClick={props.onClick}
                        modalID={props.id}
                        type="action"
                    >{props.title}</ButtonAction>
                )
            }
            {
                props.type == "hub" && (
                    <ButtonHub
                        id={props.id ? "modal-btn-"+props.id : "modal-btn"}
                        onClick={props.onClick}
                        title={props.title}
                        icon={props.icon}
                        modalID={props.id}
                    ></ButtonHub>
                )
            }
            <Modal id={props.id} width={props.width ? props.width : (window.innerWidth > 600 ? "60%": "95%")}>
                {props.children}
            </Modal>
        </div>
    )
}

export default ModalBtn
