import { userSingleton } from "../../../classes/User";
import UserRepositoryImp from "../../../infrastructure/user/user-repository-implementation";

const repository = new UserRepositoryImp();

function roleVerify(user, func) {
    // const suscription = repository.roleVerify(user.uid, user.uid).subscribe(
    //     () => {
    //         func(true)
    //     },
    //     (error) => {
    //         func(false)
    //         console.error(error)
    //     }
    // )
    // return () => suscription.unsubscribe()
    func(false)
}

export { roleVerify }
