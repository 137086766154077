import { userSingleton } from "../../../classes/User";
import ProductsRepositoryImp from "../../../infrastructure/products/products-repository-implementation";

const repo = new ProductsRepositoryImp();

function getProductByid(func, sku) {
    const suscription = repo.getById(userSingleton.uid, userSingleton.cid, sku).subscribe(
        (response) => {
            var data = JSON.parse(response.data)
            if (data.length > 0) {
                func(data[0])
            }
        },
        (error) => {
            func({})
        }
    )
    return () => suscription.unsubscribe()
}

export { getProductByid }
