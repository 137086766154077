import { userSingleton } from "../../../classes/User";
import BuysRepositoryImp from '../../../infrastructure/buys/buys-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new BuysRepositoryImp();

function listCompras(func) {
    const suscription = repo.get(userSingleton.uid, userSingleton.cid).subscribe(
        (response) => {
            /* dispatchCustomEvent("notification-popup", {message: "Compra exitosa"})
            dispatchCustomEvent("update-stock-buy", data)
            dispatchCustomEvent("process-payment", {bill: bill}) */
            func(JSON.parse(response.data))
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error listando compras", type:"error"})
        }

    )
    return () => suscription.unsubscribe()
}

export { listCompras }
