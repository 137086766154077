import './style.css'

const InsightsTab = ({ children, isActive, onClick }) => {
    return (
        <div
            className={`tab ${isActive ? 'active' : ''}`}
            onClick={onClick}
        >
            <p>{children}</p>
        </div>
    );
};


export default InsightsTab
