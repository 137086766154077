
import React, { useState } from 'react';
import './style.css'
import { dispatchCustomEvent } from '../../../app/utils/dispatch'

const InsightsTabGroup = ({ children, id }) => {

        const [activeTab, setActiveTab] = useState(null);

        function handleTabClick(tabIndex) {
            setActiveTab(tabIndex);
            dispatchCustomEvent('insights-tab-'+id+'-click', { tabIndex });
        }

        const childrenWithProps = React.Children.map(children, (child, index) =>{
            return React.cloneElement(child, {
                isActive: activeTab === index,
                onClick: () => handleTabClick(index),
            })
        })

        return (
            <div className='tab-group'>
                {childrenWithProps}
            </div>
        );
    };


export default InsightsTabGroup
