import { Observable } from 'rxjs';
import { PayMethods, Product } from '../products';

export abstract class ProductRepository {
  abstract get(uid: string, cid: string | null): Observable<Product[]>;
  abstract getById(uid: string, cid: string | null, id: string): Observable<any>;
  abstract create(uid: string, cid: string | null, body : Product): Observable<Product>;
  abstract edit(uid: string, cid: string | null, id: string, body : Product): Observable<Product>;
  abstract delete(uid: string, cid: string | null, id : string): Observable<Product>;
  abstract getPayMethods(uid: string, cid: string | null): Observable<PayMethods[]>;
  abstract createFromFile(uid: string, cid: string | null, body: FormData): Observable<any>;
}
