
import InsightsTab from '../atomos/tab'
import './style.css'
import InsightsTabGroup from './tabGroup'

const InsightsGroup = ({ children, id='insights-group' }) => {
    return (
        <div className='group'>
            <InsightsTabGroup id={id}>
                <InsightsTab>Dia</InsightsTab>
                <InsightsTab>Semana</InsightsTab>
                <InsightsTab>Mes</InsightsTab>
            </InsightsTabGroup>
            <div className='group-content'>
                {children}
            </div>
        </div>
    )
}

export default InsightsGroup
