import { Observable } from 'rxjs';
import { Carrito } from '../carrito';
import { Product } from '../../products/products';

export abstract class CarritoRepository {
  abstract get(): Observable<Carrito>;
  abstract clear(): Observable<Carrito>;
  abstract add(item: any): Observable<Product>;
  abstract remove(item: any): Observable<Product>;
  abstract update(sku: string, key: string, value: any): Observable<Product>;
  abstract getTaxes(): Observable<any>;
  abstract getTotal(): Observable<any>;
  abstract setType(type: string): Observable<any>;
}
