import './style.css'

const SearchBar = ({placeholder, onChange, vdinput, id, width="100%"}) => {

    return (
        <div style={{width: width}}>
            <input placeholder={placeholder} onChange={onChange} type='search' className='search-box' list={'lista-'+id} value={vdinput}  ></input>
        </div>
    )
}

export default SearchBar;
