//Custo css import
import './style.css'

import TopBar from '../../organismos/topBar/view'
import NotificationPopup from '../../shared/atomos/popup/popup'

function GeneralLayout(props) {
    return (
        <div className='general-layout'>
            <TopBar/>
            <NotificationPopup></NotificationPopup>
            {
                <div>
                    {props.children}
                </div>
            }
        </div>
    )

}

export default GeneralLayout
