import { userSingleton } from "../../../classes/User";
import NotasCreditoRepository from "../../../infrastructure/notascredito/notascredito-repository-implementation";
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const notasCreditoRepository = new NotasCreditoRepository();

function parseData(data) {
    var aux = {
        "totalDocuments": data.totalDocuments,
        "data": data.docs.map((doc) => {
            return {
                "number": doc.billing_reference.number,
                "uuid": doc.billing_reference.uuid,
                "customer_name": doc.customer.name,
                "customer_email": doc.customer.email,
                "customer_identification": doc.customer.identification_number,
                "sendmail": doc.sendmail,
                "emailDeliveryStatus": doc.emailDeliveryStatus,
                "state": doc.state,
                "cude": doc.cude,
                "preview": doc.preview,
                "QRStr": doc.QRStr,
                "consecutive": doc.consecutive
            }
        }),
        "docs": data.docs,
        "page": data.page,
        "perPage": data.perPage
    }
    return aux
}

function getNotasCredito(page=1, limit=5, func) {
    const notasCreditoSuscription = notasCreditoRepository.getAll(userSingleton.uid, userSingleton.cid, page, limit).subscribe(
        (data) => {
            var dataParsed = parseData(data)
            if (dataParsed.data.length === 0) {
                dataParsed.data = EmptyArray
            }
            func(dataParsed)
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error cargando notas crédito", type:"error"})
            func({"data": undefined});
        }
    )
    return () => notasCreditoSuscription.unsubscribe()
}

export { getNotasCredito }
