type Product = {
    id : number,
    sku  : string,
    name : string,
    buy_value : number,
    stock : number,
    value : number,
    sell_taxes_id : number,
    buy_taxes_id : number,
}

type ProductOrder = {
    billID: number,
    thirdID: number,
    buyDate: string,
    vencimiento: string,
    totalPay: number,
    retention: number,
};

type PayMethods = {
    id: number,
    method: string,
}

class ProductFactory {
    public static create(
        id : number,
        sku  : string,
        name : string,
        buy_value : number,
        stock : number,
        value : number,
        sell_taxes_id : number,
        buy_taxes_id : number,
    ): Product {
        return {
            id ,
            sku  ,
            name ,
            buy_value ,
            stock ,
            value ,
            sell_taxes_id ,
            buy_taxes_id ,
        };
    }
}

export { Product, ProductOrder, ProductFactory, PayMethods};
