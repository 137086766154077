import { userSingleton } from "../../../classes/User";
import ThirdsRepositoryImp from '../../../infrastructure/thirds/thirds-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const repo = new ThirdsRepositoryImp();

function getThirds(type_, func, funcFull) {
    const suscription = repo.get(userSingleton.uid, userSingleton.cid, type_).subscribe(
        (data) => {
            if (data.data?.length == 0){
                func(EmptyArray)
                funcFull(EmptyArray)
            }else{
                func(data.data)
                funcFull(data.data)
            }
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error consultando terceros", type:"error"})
            func(undefined)
        }
    )
    return () => suscription.unsubscribe()
}

export { getThirds }
