import { InsightsRepository } from '../../domain/models/insights/gateway/Insights-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class InsightsRepositoryImp extends InsightsRepository {
  get(uid: string, cid: string | null, insight: string, period: string): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get(`/insights/${insight}/${period}`);
  }
}
