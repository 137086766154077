//Custo css import
import './style.css'
import Form from 'react-bootstrap/Form';

//React import
import { useState } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';

//Widgets import
//Classes
import ModalBtn from '../../../moleculas/modalButton/hub/view';
import { uploadFromFile } from '../../../domain/usecase/products/upload-from-file';
import { ButtonBasic } from '../../../atomos/buttons';
import { dispatchCustomEvent } from '../../../utils/dispatch';

function LoadProductsFromFile(props) {

    var [loading, SetLoading] = useState(false)
    const [files, setFile] = useState(undefined)

    async function handleFile() {

        if (!files) {
            dispatchCustomEvent('notification-popup', { message: 'No se ha seleccionado un archivo' });
            dispatchCustomEvent('loading-btn-product-load', [])
            return
        }

        SetLoading(true)
        const formData = new FormData();
        formData.append(
            'file',
            files[0],
            files[0].name
        );
        uploadFromFile(formData, SetLoading)
    }

    return (
        <ModalBtn
            id="loadStockFromFile"
            icon={<AiOutlineUpload></AiOutlineUpload>}
            title="Cargar"
        >
            <Form enctype="multipart/form-data"  action="javascript:void(0);" onSubmit={() => {handleFile()}}>
                    <a style={{ color: "blue" }} href="https://public-pos.s3.us-west-2.amazonaws.com/plantilla_pos_products.xlsx" action="javascript:void(0);" >Descarga la plantilla</a>
                    <hr></hr>
                    <Form.Control style={{ marginTop: "30px" }} multiple type="file" onChange={(e) => [e.preventDefault(), setFile(e.target.files)]} />
                    <br></br>
                    <ButtonBasic
                        id="product-load"
                        title="Enviar Archivo"
                        width="96%"
                        showLoading={true}
                        style={{ width: "96%", margin:"15px" }}
                        type="submit"
                    ></ButtonBasic>
                </Form>
        </ModalBtn>
    )
}

export default LoadProductsFromFile
