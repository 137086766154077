//Custo css import
import './style.css'

//Boostrap import
import { useEffect, useState } from 'react';
import { ButtonBasic } from '../../atomos/buttons';
import { addItemToCar, clearCar, cloneItem, updateItem, setType } from '../../domain/usecase/carrito/carrito';
import { getTaxes } from '../../domain/usecase/tax/get-taxes-taxes';

function SellModule(props) {

    const [car, SetCar] = useState([])
    const [taxGroup, SetTaxGroup] = useState([])
    const [total, SetTotal] = useState(0);
    const [tercero, SetTercero] = useState(undefined)
    const [taxes, SetTaxes] = useState([])
    const [valueType, setValueType] = useState("value")
    var vdinput;

    useEffect(() => {
        setType(props.type ? props.type : "sell")
        if(props.type && props.type == "buy") {
            setValueType("buy_value")
        }

        window.addEventListener('btn-thirds-add', (e) => {SetTercero(e.detail.data)});
        window.addEventListener('update-sellmodule', updateModule);
        window.addEventListener('btn-add-product', addItemToCar);
        window.addEventListener('btn-clone-product', cloneItem);
        return () => {
            window.removeEventListener('btn-thirds-add', (e) => {SetTercero(e.detail.data)});
            window.removeEventListener('update-sellmodule', updateModule);
            window.removeEventListener('btn-add-product', addItemToCar);
            window.removeEventListener('btn-clone-product', cloneItem);
        };
    }, [])

    function updateModule(event) {
        handleTax()
        SetCar([...event.detail.data.car])
        SetTaxGroup([...event.detail.data.tax])
        SetTotal(event.detail.data.total)
    }

    function handleTax() {
        if (taxes.length == 0) {
            getTaxes(props.type ? props.type : "sell", SetTaxes)
        }
    }

    function update(item, key) {
        let cnt = prompt("Precio:", item[key]);
        if (cnt != "") {
            updateItem(item["auxsku"], key, parseFloat(cnt))
        }
    }

    return (
        <div className='sell-box-module' >
            <div className='sell-client-box'>
                 {
                    <div>
                    <p><span style={{fontWeight:"bold"}}>Nombre:</span> {tercero? tercero.name : "--------"}</p>
                    <p><span style={{fontWeight:"bold"}}>Documento:</span> {tercero? tercero.identification : "--------"}</p>
                    <p><span style={{fontWeight:"bold"}}>Email:</span> {tercero? tercero.email : "--------"}</p>
                </div>
                 }
            </div>

            <div className='sell-items-box'>
                <table className="table" style={{ "fontSize": "15px" }}>
                    <thead>
                        <tr >
                            <th scope="col">Nombre</th>
                            <th scope="col">Unitario</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">SubTotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            car.map(item => {
                                return (
                                    <tr >
                                        <td>{item ? item.name : "Null"}</td>
                                        <td onClick={() => {update(item, valueType)}}>{item ? item[valueType] : 0}</td>
                                        <td onClick={() => {update(item, "amount")}}>{item.amount ? item.amount : 0}</td>
                                        <td>{item ? item.total : 0}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className='sell-tax-box'>
                <table class="table" style={{ "fontSize": "15px" }}>
                    <thead>
                        <tr>
                            <th scope="col">Impuesto</th>
                            <th scope="col">Valor</th>
                            <th scope="col">SubTotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            taxGroup.map(tax => {
                                return (
                                    <tr hidden={tax.id == 3 || tax.id == 2 || tax.id == 0 || tax.id == undefined}>
                                        <td>{Array.isArray(taxes) ? (taxes.filter(i => i.id == tax.id)[0]?.description) : "Null"}</td>
                                        <td>{tax.value}</td>
                                        <td>{tax.subtotal}</td>
                                    </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>

            <div className='sell-total-box'>
                <p>Total</p>
                <p>{total}</p>
            </div>

            <div className='sell-button-box'>
                <ButtonBasic onClick={() => {clearCar()}}>Cancelar</ButtonBasic>
                {props.children}
            </div>
        </div>


    )

}

export default SellModule
