import { userSingleton } from "../../../classes/User";
import InvoiceRepositoryImp from '../../../infrastructure/invoice/invoice-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const invoiceRepository = new InvoiceRepositoryImp();

function getInvoices(page=1, limit=100, func) {
    const invoicesSuscription = invoiceRepository.getAll(userSingleton.uid, userSingleton.cid, page, limit).subscribe(
        (data) => {
            if (data.data.length === 0) {
                data.data = EmptyArray
            }
            func(data)
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error cargando facturas", type:"error"})
            func({"data": undefined});
        }
    );
    return () => invoicesSuscription.unsubscribe()
}

export { getInvoices }
