import { SellsRepository } from '../../domain/models/sells/gateway/sells-repository';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class SellsRepositoryImp extends SellsRepository {
  create(uid: string, cid: string | null, data: any): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.post(`/sell`, data);
  }
}
