//Custo css import
import './style.css'

//Boostrap import
import { AiFillSetting } from 'react-icons/ai';

import Form from 'react-bootstrap/Form';

//React import
import { useEffect, useState } from 'react';

import {setParametrization } from './controller';

import { lang } from '../../utils/langs';
import ModalBtn from '../../moleculas/modalButton/hub/view';
import { getTaxes } from '../../domain/usecase/tax/get-taxes-taxes';
import { getPayMethods } from '../../domain/usecase/products/get-pay-methods';
import { getParameterization } from '../../domain/usecase/parametrization/get-parametrization';
import { getAccounts } from '../../domain/usecase/parametrization/get-accounts';

function Parameterization(props) {

    const [open, SetOpen] = useState(true)
    const [accounts, SetAccounts] = useState([])
    const [parametrization, SetParametrization] = useState([])
    const [taxes, SetTaxes] = useState([])
    const [aux_ventas, SetAuxVentas] = useState(undefined)
    const [aux_compras, SetAuxCompras] = useState(undefined)
    const [paymethods, SetPayMethods] = useState([])

    useEffect(()=>{
        SetAuxVentas(aux_acc('sell'))
        SetAuxCompras(aux_acc('buy'))
    }, [parametrization])

    function aux_acc(key) {
        var aux = parametrization.filter(aux_itm => (aux_itm.param_type === key))
        aux = aux[0] ? aux[0] : 0
        var aux_accounts = accounts.filter(aux_itm => (aux_itm.id === aux.users_accounts_id))
        aux_accounts = aux_accounts[0] ? aux_accounts[0] : undefined
        return aux_accounts
    }

    function funopen() {
        SetOpen(false)
        getTaxes("all", SetTaxes)
        getPayMethods(SetPayMethods)
        getParameterization(SetParametrization)
        getAccounts(SetAccounts)
    }

    return (
        <div>
            <ModalBtn
                id="parametrization"
                type="hub"
                className='btn-acc-rep-modal'
                icon={<AiFillSetting></AiFillSetting>}
                title="Parametros"
                onClick={funopen}
            >
            <div style={{ width: "97%", overflowY: "scroll", height: "80vh", margin: "22px", marginTop: "30px" }}>
                        <h2>Formas de pago</h2>
                        <hr style={{ width: "96%" }}></hr>
                        {
                            paymethods.map((item) => {
                                var key = 'sell_' + item.method
                                var aux_accounts = aux_acc(key)
                                return (
                                    <Form.Group className="mb-3" style={{ "width": "95%", "margin": "5px", marginTop: "30px" }}>
                                        <Form.Label>Ventas con {lang("es", item.method)}:</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            onChange={(data) => [setParametrization(data.target.value, aux_accounts ? aux_accounts.id : -1, key).then(res => {SetParametrization(res)})]}
                                        >
                                            {
                                                aux_accounts ?
                                                    <option value={-1}>{aux_accounts.account} - {aux_accounts.description}</option>
                                                    : <option>{ } - { }</option>
                                            }
                                            {
                                                accounts.map((account) => {
                                                    return (<option value={account.id}>{account.account} - {account.description}</option>)
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                )
                            })
                        }
                        {
                            paymethods.map((item) => {
                                var key = 'buy_' + item.method
                                var aux_accounts = aux_acc(key)
                                return (
                                    <Form.Group className="mb-3" style={{ "width": "95%", "margin": "5px", marginTop: "30px" }}>
                                        <Form.Label>Compras con {lang("es", item.method)}:</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            onChange={(data) => setParametrization(data.target.value, aux_accounts ? aux_accounts.id : -1, key).then(res => {SetParametrization(res)})}
                                        >
                                            {
                                                aux_accounts ?
                                                    <option>{aux_accounts.account} - {aux_accounts.description}</option>
                                                    : <option>{ } - { }</option>
                                            }
                                            {
                                                accounts.map((account) => {
                                                    return (<option value={account.id}>{account.account} - {account.description}</option>)
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                )
                            })
                        }
                        <h2>Compras y Ventas</h2>
                        <hr style={{ width: "96%" }}></hr>
                        <Form.Group className="mb-3" style={{ "width": "95%", "margin": "5px", marginTop: "30px" }}>
                            <Form.Label>Ventas:</Form.Label>
                            <Form.Select aria-label="Default select example"
                                onChange={(data) => setParametrization(data.target.value, aux_ventas ? aux_ventas.id : -1, "sell").then(res => {SetParametrization(res)})}
                            >
                                {
                                    aux_ventas ?
                                        <option>{aux_ventas.account} - {aux_ventas.description}</option>
                                        : <option>{ } - { }</option>
                                }
                                {
                                    accounts.map((account) => {
                                        return (<option value={account.id}>{account.account} - {account.description}</option>)
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" style={{ "width": "95%", "margin": "5px", marginTop: "30px" }}>
                            <Form.Label>Compras:</Form.Label>
                            <Form.Select aria-label="Default select example"
                                onChange={(data) => setParametrization(data.target.value, aux_compras ? aux_compras.id : -1, "buy").then(res => {SetParametrization(res)})}
                            >
                                {
                                    aux_compras ?
                                        <option>{aux_compras.account} - {aux_compras.description}</option>
                                        : <option>{ } - { }</option>
                                }
                                {
                                    accounts.map((account) => {
                                        return (<option value={account.id}>{account.account} - {account.description}</option>)
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <h2>Impuestos</h2>
                        <hr style={{ width: "96%" }}></hr>
                        {
                            taxes?.map((item) => {
                                var key = 'tax_' + item.id + '_' + item.type
                                var aux_accounts = aux_acc(key)
                                return (
                                    <Form.Group className="mb-3" style={{ "width": "95%", "margin": "5px", marginTop: "30px" }}>
                                        <Form.Label>{item.description}:</Form.Label>
                                        <Form.Select aria-label="Default select example"
                                            onChange={(data) => setParametrization(data.target.value, aux_accounts ? aux_accounts.id : -1, key).then(res => {SetParametrization(res)})}
                                        >
                                            {
                                                aux_accounts ?
                                                    <option>{aux_accounts.account} - {aux_accounts.description}</option>
                                                    : <option>{ } - { }</option>
                                            }
                                            {
                                                accounts.map((account) => {
                                                    return (<option value={account.id}>{account.account} - {account.description}</option>)
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                )
                            })
                        }
                    </div>
            </ModalBtn>
        </div>
    )
}

export default Parameterization
