export function formatMoney(amount) {
    return amount.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP', // Puedes cambiar esto según la moneda que necesites
        maximumFractionDigits: 0
    });
}

export function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`
}
