import { CarritoRepository } from '../../domain/models/carrito/gateway/invoice-repository';

export default class CarritoRepositoryImp extends CarritoRepository {
  private static instance: CarritoRepositoryImp;
  private items: any[] = [];
  private taxes: any[] = [];
  private total: any = 0;
  private type: any = "";
  private aux : any = {
    "sell": "sell_taxes_id",
    "buy": "buy_taxes_id",
  }
  private aux2 : any = {
    "tax":{
      "sell": "sell_tax",
      "buy": "buy_tax",
    },
    "value":{
      "sell": "value",
      "buy": "buy_value",
    }
  }

  private constructor() {
    super();
  }

  public static getInstance(): CarritoRepositoryImp {
    if (!CarritoRepositoryImp.instance) {
      CarritoRepositoryImp.instance = new CarritoRepositoryImp();
    }
    return CarritoRepositoryImp.instance;
  }

  add(item: any): any {
    this.items.push(item);
  }

  setType(type: string): any {
    this.type = type
  }

  getType(): any {
    return this.type
  }

  getTypes(): any {
    return this.aux2
  }

  remove(sku: string): any {
    this.items = this.items.filter(item => item.sku !== sku);
  }

  get(): any {
    return this.items;
  }

  getTaxes(): any {
    this.taxes = []
    this.items.forEach((item) => {
        const tax = this.taxes.find(tax => tax["id"] === item[this.aux[this.type]]);
        if (tax) {
            tax["subtotal"] += item["auxtax"];
        } else {
            this.taxes.push({
                "id": item[this.aux[this.type]],
                "value": item[this.aux2["value"][this.type]],
                "subtotal": item["auxtax"]
            });
        }
    });

    return this.taxes;
  }

  clear(): any {
    this.items = [];
    this.taxes = [];
  }

  update(auxsku: string, key: string, value: any): any {
    this.items.forEach((item: any) => {
      if (item["auxsku"] === auxsku) {
        item[key] = value;
        item.total = parseFloat(item[this.aux2["value"][this.type]]) * parseFloat(item.amount);
        item["auxtax"] =  item.total - (item.total / (1 + parseFloat(item["sell_tax"])))
      }
    });
  }

  getTotal(): any {
    this.total = 0;
    this.items.forEach((item) => {
        this.total += parseFloat(item[this.aux2["value"][this.type]]) * parseFloat(item.amount);
    });
    return this.total
  }
}
