import { userSingleton } from "../../../classes/User";
import ProductsRepositoryImp from "../../../infrastructure/products/products-repository-implementation";
import { EmptyArray } from "../../models/empty/empty";

const repo = new ProductsRepositoryImp();

function getProducts(func) {
    const suscription = repo.get(userSingleton.uid, userSingleton.cid).subscribe(
        (data) => {
            if (data.length == 0){
                func(EmptyArray)
            }else{
                func(data)
            }
        },
        (error) => {
            console.error(error)
        }
    )
    return () => suscription.unsubscribe()
}

export { getProducts }
