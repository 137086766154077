//Custo css import
import './style.css'

//Boostrap import
import Form from 'react-bootstrap/Form';
import { BiEdit, BiSearch } from 'react-icons/bi';

//React import
import { useEffect, useState } from 'react';
import TaxRepositoryImp from '../../../../infrastructure/taxes/tax-repository-implementation';
import { userSingleton } from '../../../../classes/User';
import { ButtonAction, ButtonBasic } from '../../../../atomos/buttons';
import ModalBtn from '../../../../moleculas/modalButton/hub/view';
import { getProductByid } from '../../../../domain/usecase/products/get-products-by-id';
import { Spinner } from 'react-bootstrap';
import ProductsTable from '../../../../products/organismos/productsTable/view';
import { dispatchCustomEvent } from '../../../../utils/dispatch';
import { editBuy } from '../../../../domain/usecase/buys/edit';

const EditPurshaseItem = ({ data }) => {

    const taxRepository = new TaxRepositoryImp();

    var [loading, SetLoading] = useState(false)
    var [selltaxes, SetSellTaxes] = useState([])
    var [buytaxes, SetBuyTaxes] = useState([])
    const [products, SetProducts] = useState({})
    const [productChange, SetProductChange] = useState({})
    const [showLoading, SetShowLoading] = useState(false)

    useEffect(() => {
        window.addEventListener('btn-update-edit-compra', handleProductsList);
        return () => {
            window.removeEventListener('btn-update-edit-compra', handleProductsList);
        };
    }, [])

    useEffect(() => {
        if ("sku" in productChange) {
            SetProducts(productChange)
            SetShowLoading(false)
        }
    }, [productChange])

    function update() {
        getProductByid(SetProducts, data.sku)
        const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, "sell").subscribe(
            (data) => {
                SetSellTaxes(data)
            },
            (error) => {
                console.log(error)
            }
        )
        const taxSuscription2 = taxRepository.get(userSingleton.uid, userSingleton.cid, "buy").subscribe(
            (data) => {
                SetBuyTaxes(data)
            },
            (error) => {
                console.log(error)
            }
        )
        return () => {
            taxSuscription.unsubscribe()
            taxSuscription2.unsubscribe()
        }
    }

    function handleProductsList(e) {
        if (e.defaultPrevented) {
            return;
        }
        if (e.detail.data.sku != data?.sku) {
            var aux = window.document.getElementById('sku-form-control')
            aux.value = e.detail.data.sku
            getProductByid(SetProductChange, e.detail.data.sku)
            dispatchCustomEvent("close-edit-compra-sku-modal", [])
        }
    }

    function changeProduct(value) {
        SetShowLoading(true)
        getProductByid(SetProductChange, value)
    }

    function edit(prod) {
        SetLoading(true)
        prod.preventDefault()
        var value
        if (prod.target[2].value === "") {
            value = data.value
        } else {
            value = prod.target[2].value
        }
        var name
        if (prod.target[1].value === "") {
            name = data.name
        } else {
            name = prod.target[1].value
        }
        var taxID
        if (prod.target[3].value === "") {
            taxID = data.name
        } else {
            taxID = prod.target[3].value
        }
        var edit = {
            "sku": prod.target[0].value,
            "bill_id": data.bill_id,
            "value": data?.value,
            "amount": data?.amount,
            "row_id": data.id,
            "product": products
        }
        dispatchCustomEvent('list-items-compra-edit-item-'+data.bill_id, edit)
        dispatchCustomEvent("close-editProduct-"+edit["row_id"]+"-modal", {})
    }

    return (
        <ModalBtn
            id={"editProduct-" + data.id}
            title={<BiEdit></BiEdit>}
            type="action"
            onClick={update}
        >
            <div>
                <h2>Editar Compra</h2>
                <Form id="compra-form" action="javascript:void(0);" onSubmit={(data) => { edit(data) }}>
                    <Form.Group style={{ width: "95%" }}>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="sku" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Sku</Form.Label>
                                <div style={{ display: "flex" }}>
                                    <Form.Control id='sku-form-control' type="text" defaultValue={data?.sku} onChange={(e) => {changeProduct(e.target.value)}}/>
                                    <Spinner hidden={!showLoading}></Spinner>
                                    <ModalBtn id="edit-compra-sku" type="action" width="95vw">
                                        <ProductsTable hiddenAction={true}>
                                            <ButtonAction btnKey="update-edit-compra">
                                                <BiSearch></BiSearch>
                                            </ButtonAction>
                                        </ProductsTable>
                                    </ModalBtn>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-name" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" defaultValue={products?.name} disabled={true} onChange={(e) => products.name = e.target.value} />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Compra</Form.Label>
                                <Form.Control  type="text" defaultValue={data?.value} onChange={(e) => data.value = e.target.value} />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control   type="text" defaultValue={data?.amount} onChange={(e) => data.amount = e.target.value} />
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-sell-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Venta</Form.Label>
                                <Form.Control type="text" defaultValue={products?.value} disabled={true} onChange={(e) => products.value = e.target.value} />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Venta</Form.Label>
                                <Form.Select name="color" id="tax_selector" disabled={true}>
                                    <option value={products?.sell_taxes_id}>
                                        {selltaxes.filter(i => i.id === products?.sell_taxes_id).map(i => i.description)}
                                    </option>
                                    {
                                        selltaxes ?
                                            selltaxes.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Compra</Form.Label>
                                <Form.Select name="color" id="tax_buy_selector">
                                    <option value={products?.buy_taxes_id}>
                                        {buytaxes.filter(i => i.id === products?.buy_taxes_id).map(i => i.description)}
                                    </option>
                                    {
                                        buytaxes ?
                                            buytaxes.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </Form.Group>
                    <ButtonBasic
                        id="product-edit"
                        title="Guardar"
                        width="96%"
                        showLoading={true}
                        style={{ width: "96%", margin: "15px" }}
                        type="submit"
                    ></ButtonBasic>
                </Form>
            </div>
        </ModalBtn>
    )
}

export default EditPurshaseItem
