import { UserRepository } from '../../domain/models/user/gateway/user-repository';
import { IdType, User, UserShare } from '../../domain/models/user/user';
import HttpClient from '../helpers/http-client';
import { Observable } from 'rxjs';

export default class UserRepositoryImp extends UserRepository {
  get(uid: string, cid: string | null): Observable<User> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<User>(`v1/user?uid=${cid ? cid : uid}`);
  }

  getSharedProfiles(uid: string, cid: string | null): Observable<UserShare[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<UserShare[]>(`v1/shared`);
  }

  listIdTypes(uid: string, cid: string | null): Observable<IdType[]> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<IdType[]>(`v1/idType`);
  }

  roleVerify(uid: string, cid: string | null): Observable<any> {
    const httpClient = new HttpClient(uid, cid);
    return httpClient.get<any>(`role-verify`);
  }
}
