
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


//app/views Import
import Login from '../app/views/login/view'
import Register from '../app/views/register/view'
import Pos from '../app/views/pos/view'
import Contador from '../app/views/hub/view'
import Bill from '../app/organismos/bill/view';
import Invoice from '../app/views/invoice/view'
import Compras from '../app/views/compras/view'

export class Accountant {
    constructor() {
        // this.roles_id = roles_id
    }

    getRoutes() {
        return (
            <Router>
                <Routes>
                <Route path="/invoice" element={<Invoice />} />
                    <Route path="/bill" element={<Bill />} />
                    <Route path="/pos" element={<Pos />} />
                    <Route path="/compras" element={<Compras/>} />
                    <Route path="/hub" element={<Contador />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Login />} />
                    <Route path="*" element={<Contador />} />
                </Routes>
            </Router>
        )
    }

}
