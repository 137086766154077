import { userSingleton } from "../../../classes/User";
import ThirdsRepositoryImp from '../../../infrastructure/thirds/thirds-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new ThirdsRepositoryImp();

function editThird(data, identification, SetThird) {
    const suscription = repo.edit(userSingleton.uid, userSingleton.cid, data, identification).subscribe(
        (data) => {
            dispatchCustomEvent("notification-popup", {message: "Edicion exitosa"})
            dispatchCustomEvent("update-thirds", [])
            dispatchCustomEvent("close-edit-third-"+identification+"-modal", [])
            var allInputs = document.querySelectorAll('input');
            allInputs.forEach(singleInput => singleInput.value = '');
            dispatchCustomEvent("loading-btn-third-edit", {})
            SetThird({})
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error editando", type:"error"})
            dispatchCustomEvent("loading-btn-third-edit", {})
        }
    )
    return () => suscription.unsubscribe()
}

export { editThird }
