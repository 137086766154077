import { userSingleton } from "../../../classes/User";
import InvoiceRepositoryImp from '../../../infrastructure/invoice/invoice-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new InvoiceRepositoryImp();

function createInvoice(bill_id) {
    var data = {
        "model": "invoice",
        "id": bill_id
    }
    const suscription = repo.create(userSingleton.uid, userSingleton.cid, data).subscribe(
        (data) => {
            if (data.responseSQS === 200){
                dispatchCustomEvent("notification-popup", {message: "Factura enviada para procesar"})
            }
        },
        (error) => {
            if (error.response.status == 429) {
                dispatchCustomEvent("notification-popup", {message: "Alcanzaste el límite de facturas", type:"error"})
            }
        }
    )
    return () => suscription.unsubscribe()
}

export { createInvoice }
