import UserRepositoryImp from '../../../infrastructure/user/user-repository-implementation'
import ModalBtn from '../../../moleculas/modalButton/hub/view';
import Loading from '../../../atomos/loading/loadingBtn/view';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiSave } from 'react-icons/bi';
import { useState } from 'react';
import './style.css'
import { createThird } from '../../../domain/usecase/thirds/create';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import { validateEmail, validateId } from '../../../utils/validate';
import { ButtonAction, ButtonBasic } from '../../../atomos/buttons';

function NewThird(props) {

    const userRepository = new UserRepositoryImp();

    const [idType, SetIdType] = useState([])
    const [third, SetThird] = useState({})

    function handleClick() {
        const thirdTypeSuscription = userRepository.listIdTypes().subscribe(
            (data) => {
                SetIdType(data)
            },
            (error) => {
                console.error(error)
            }
        )
        return () => thirdTypeSuscription.unsubscribe()
    }

    function handleChange(e) {
        e.preventDefault()
        var aux = third
        var key = e.target.id.replace("third-", "")
        if(key === "email") {
            if(!validateEmail(e.target.value)) {
                e.target.style.borderColor = "red"

            }else{
                e.target.style.borderColor = ""

            }
        }
        if(key === "identification") {
            if(!validateId(e.target.value)) {
                e.target.style.borderColor = "red"

            }
            else{
                e.target.style.borderColor = ""

            }
        }
        if(key === "phone") {
            if(!validateId(e.target.value)) {
                e.target.style.borderColor = "red"

            }
            else{
                e.target.style.borderColor = ""

            }
        }
        aux[key] = e.target.value
        SetThird(aux)
    }



    async function handleSubmit() {
        var data = third
        console.log(data)
        if (!data.name || !data.email || !data.identification || !data.phone) {
            dispatchCustomEvent("loading-btn-third-new", {})
            dispatchCustomEvent("notification-popup", { message: "Debe completar los campos", type: "error" })
            return
        }
        if((data.email && !validateEmail(data.email)) || (data.phone && !validateId(data.phone)) || (data.identification && !validateId(data.identification))) {
            dispatchCustomEvent("loading-btn-third-new", {})
            dispatchCustomEvent("notification-popup", { message: "Verifica los campos", type: "error" })
            return
        }
        const existeIdentificationsId = "identifications_id" in data;
        if(!existeIdentificationsId) {
            data["identifications_id"] = "1"
        }
        createThird(data, props.type ? props.type : "client", SetThird)
    }

    return (
        <div>
            <ModalBtn
                id='new-third'
                title={
                    props.type == "client" && ("Agregar cliente")
                    || props.type == "provider" && ("Agregar Proveedor")
                }
                onClick={handleClick}
            >
                <div className='inventario-box' style={{ marginTop: "15px" }}>
                    <h3>{
                        props.type == "client" && ("Agregar cliente")
                        || props.type == "provider" && ("Agregar Proveedor")
                    }</h3>
                    <Form action="javascript:void(0);" id="third-form" onChange={handleChange}>
                        <Form.Group >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control id="third-name"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Email</Form.Label>
                            <Form.Control id="third-email"></Form.Control>
                        </Form.Group>
                        <div style={{ display: "flex" }}>
                            <Form.Group >
                                <Form.Label>Documento</Form.Label>
                                <Form.Control id="third-identification"></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select id="third-identifications_id" >
                                    {
                                        idType ?
                                            idType.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.type}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <Form.Group >
                            <Form.Label>Telefono</Form.Label>
                            <Form.Control id="third-phone"></Form.Control>
                        </Form.Group>
                        <ButtonBasic
                            onClick={() => [handleSubmit()]}
                            id="third-new"
                            title="Guardar"
                            width="96%"
                            showLoading={true}
                            style={{ width: "96%", margin:"15px" }}
                        ></ButtonBasic>
                    </Form>
                </div>
            </ModalBtn>
        </div>
    )
}

export default NewThird
