import React, { useEffect, useState } from 'react';
import './popup.css'; // Asegúrate de crear y estilizar este archivo CSS

const NotificationPopup = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState("msg")
    const [type, setType] = useState("")

    useEffect(() => {
        window.addEventListener('notification-popup', handleCustomEvent);
        return () => {
            window.removeEventListener('notification-popup', handleCustomEvent);
        };
    }, []);

    function handleCustomEvent(event){
        setMessage(event.detail.data.message)
        setType(event.detail.data.type ? event.detail.data.type : "")
        setIsVisible(true)
        setTimeout(() => {setIsVisible(false)}, 3000)
    }

    if (!isVisible) return null;

    return (
        <div className={"popup-overlay"}>
            <div className={"popup-content "+type}>
                <span className="popup-message">{message}</span>
                <button className="popup-close" onClick={() => { setIsVisible(false) }}>x</button>
            </div>
        </div>
    );
};

export default NotificationPopup;
