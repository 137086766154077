import { userSingleton } from "../../../classes/User";
import BuysRepositoryImp from '../../../infrastructure/buys/buys-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new BuysRepositoryImp();

function createBuy(data) {
    const suscription = repo.create(userSingleton.uid, userSingleton.cid, data).subscribe(
        (bill) => {
            dispatchCustomEvent("notification-popup", {message: "Compra exitosa"})
            dispatchCustomEvent("update-stock-buy", data)
            dispatchCustomEvent("process-payment", {bill: bill})
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error en la compra", type:"error"})
            // dispatchCustomEvent("process-payment", {bill: error})
        }
    )
    return () => suscription.unsubscribe()
}

export { createBuy }
