//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';
import { AiFillSetting } from 'react-icons/ai';

import Form from 'react-bootstrap/Form';
import LoadingBtn from '../../atomos/loading/loadingBtn/view';
import { userSingleton } from '../../classes/User';


//React import
import { useEffect, useState } from 'react';

import { loadImage, updateUserProfile } from './controller';

import { BiSave } from 'react-icons/bi';
import ModalBtn from '../../moleculas/modalButton/hub/view';
import { getProfile } from '../../domain/usecase/user/get-profile';
import { getSuscription } from '../../domain/usecase/suscriptions/get-suscription';
import { ButtonBasic } from '../../atomos/buttons';
import { cancelSuscription } from '../../domain/usecase/suscriptions/cancel-suscription';
import { dispatchCustomEvent } from '../../utils/dispatch';

function Profile(props) {

    const [profileImage, setProfileImage] = useState(null)
    const [profileData, SetProfileData] = useState(NaN)
    const [loading, SetLoading] = useState(false)

    const [isDisableSave, SetDisableSave] = useState(false)
    const [suscription, SetSuscription] = useState({ "state": "no_suscription" })

    useEffect(() => {
        update()
    }, [])

    function update() {
        getProfile(SetProfileData)
        setProfileImage(profileData.avatar)
        getSuscription(SetSuscription)
    }

    function cancel() {
        if(window.confirm('Estas seguro de cancelar la suscripcion')){
            cancelSuscription()
            update()
        }
    }

    function updateProfile() {
        SetLoading(true)
        updateUserProfile().then((data) => {
            SetProfileData(data)
            SetLoading(false)
            dispatchCustomEvent("update-top-bar", undefined)
        }).catch(() => {window.alert("Error actualizando metodo de pago")})
    }

    return (
        <div>
            <ModalBtn
                id="profile"
                type="hub"
                className='btn-acc-rep-modal'
                icon={<AiFillSetting></AiFillSetting>}
                title="Perfil"
                onClick={update}
            >
                <div style={{ width: "97%", overflowY: "scroll", maxHeight:"85vh", margin: "22px", marginTop: "30px" }}>
                    <h2>Perfil</h2>
                    <hr style={{ width: "96%" }}></hr>
                    <div style={{ textAlign: "center" }}>
                        <img id="avatar-image" style={{ width: "200px", height: "200px", borderRadius: "200px", objectFit: "contain", backgroundColor: "rgba(0,0,0,0.2)" }} src={profileImage}></img>
                    </div>
                    {
                        userSingleton.roles_id === 1 && (
                            <div>
                                <hr></hr>
                                {
                                    suscription?.state === "no_suscription" &&
                                    (
                                        <Button onClick={() => [window.location.replace("/planes")]} style={{ position: "relative", width: "96%", backgroundColor: "rgba(0,0,0,0)", color: "black", borderColor: "black" }} variant="primary" type="submit">
                                            {
                                                suscription?.state === "no_suscription" && ("Mejora tu plan y remueve todos los limites")
                                            }
                                        </Button>
                                    )
                                }

                                {
                                    suscription?.state === "aprobe" &&
                                    (
                                        <div style={{width:"100%"}}>
                                            <div className='plan-info'>
                                                <p style={{fontSize:"17px"}}>Plan: <span style={{fontWeight:"bold"}}>{suscription?.name}</span></p>
                                            </div>
                                            <div style={{ display: "flex", justifyContent:"space-around" }}>
                                                {/* <ModalBtn
                                                    title="Cambiar metodo de pago"
                                                >
                                                    <CardToken plan={suscription} type="update"></CardToken>
                                                </ModalBtn> */}
                                                <ButtonBasic onClick={cancel}>Cancelar suscripcion</ButtonBasic>
                                            </div>
                                        </div>
                                    )
                                }
                                <hr></hr>
                            </div>
                        )
                    }
                    <Form className="form-profile-data" action="javascript:void(0);" onSubmit={() => {updateProfile()}} >
                        <Form.Group>
                            <Form.Label>Imagen perfil</Form.Label>
                            <Form.Control id="profile-image" style={{ margin: "5px" }} multiple type="file" onChange={(e) => [SetDisableSave(true), loadImage(e.target.files[0]).then(res => [setProfileImage(res.url), SetDisableSave(false)])]} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Company name</Form.Label>
                            <Form.Control placeholder={profileData ? profileData.companyName : ''} id="profile-company-name"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Name</Form.Label>
                            <Form.Control placeholder={profileData ? profileData.name : ''} id="profile-user-name"></Form.Control>
                        </Form.Group>
                        <Form.Group  >
                            <Form.Label>Phone</Form.Label>
                            <Form.Control placeholder={profileData ? profileData.phone : ''} id="profile-phone"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Direccion</Form.Label>
                            <Form.Control placeholder={profileData ? profileData.address : ''} id="profile-address"></Form.Control>
                        </Form.Group>
                        <Button disabled={isDisableSave} style={{ width: "100%", marginTop:"20px" }} variant="primary" type="submit">
                            <LoadingBtn loading={loading}> <BiSave></BiSave> Guardar cambios </LoadingBtn>
                        </Button>
                    </Form>
                </div>
            </ModalBtn>
        </div>
    )
}

export default Profile
