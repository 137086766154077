import { userSingleton } from "../../../classes/User";
import BuysRepositoryImp from '../../../infrastructure/buys/buys-repository-implementation';
import { dispatchCustomEvent } from "../../../utils/dispatch";

const repo = new BuysRepositoryImp();

function editBuy(data) {
    const suscription = repo.edit(userSingleton.uid, userSingleton.cid, data).subscribe(
        (response) => {
            dispatchCustomEvent("notification-popup", {message: "Editado con exito"})
            dispatchCustomEvent("clean-purchase-"+data.bill_id, data)
            dispatchCustomEvent("close-compra-"+data.bill_id+"-modal", {})
            dispatchCustomEvent("reload-list-compras", {})
            dispatchCustomEvent("update-stock", {})
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error editando", type:"error"})
        }
    )
    return () => suscription.unsubscribe()
}

export { editBuy }
