import { userSingleton } from "../../../classes/User";
import InvoiceRepositoryImp from '../../../infrastructure/invoice/invoice-repository-implementation'
import { dispatchCustomEvent } from "../../../utils/dispatch";
import { EmptyArray } from "../../models/empty/empty";

const invoiceRepository = new InvoiceRepositoryImp();

function getInvoicesByNumber(number, prefix, setFunction, onChange=null) {
    const invoicesSuscription = invoiceRepository.getByNumber(userSingleton.uid, userSingleton.cid, number, prefix).subscribe(
        (data) => {
            if (data.data.length === 0) {
                data.data = EmptyArray
            }
            setFunction(data)
            if(onChange) onChange(data)
        },
        (error) => {
            dispatchCustomEvent("notification-popup", {message: "Error al buscar factura", type:"error"})
            setFunction(undefined);
        }
    );
    return () => invoicesSuscription.unsubscribe()
}

export { getInvoicesByNumber }
