import ThirdsRepositoryImp from '../../../infrastructure/thirds/thirds-repository-implementation'
import ButtonAction from '../../../atomos/buttons/buttonAction/view';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import { deleteThird } from './controller'
import Table from '../../../moleculas/tablas/view';
import { AiFillDelete } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import NewThird from '../newThird/view';
import './style.css'
import EditThird from '../editThird/view';
import { getThirds } from '../../../domain/usecase/thirds/get';

function ThirdsTable(props) {

    const [thirdFull, SetThirdsFullList] = useState([]);
    const [third, SetThirds] = useState([]);

    var vdinput;

    useEffect(() => {
        if (props.forceLoadData && thirdFull.length === 0){
            updateThirds()
        }
        window.addEventListener('update-thirds', updateThirds);
        window.addEventListener('btn-thirds-add', handleAddThird);
        window.addEventListener('btn-thirds-delete', handleDelete);
        return () => {
            window.removeEventListener('update-thirds', updateThirds);
            window.removeEventListener('btn-thirds-add', handleAddThird);
            window.removeEventListener('btn-thirds-delete', handleDelete);
        };
    }, []);

    function updateThirds() {
        getThirds(props.type ? props.type : "client", SetThirds, SetThirdsFullList)
    }

    function handleAddThird(event) {
        dispatchCustomEvent('close-'+props.id+'-modal', [])
    }

    function handleDelete(event) {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();
        deleteThird(event.detail.data.id, props.type ? props.type : "client").then((res) => {
            window.alert(res["message"])
            if (res["status"] == "success") {
                SetThirds(res["data"])
                SetThirdsFullList(res["data"])
            }
        })
    }

    function findAndAdd(e) {
        var aux_search = e.target.value
        var aux_inv = thirdFull.filter(
            third => (
                third.identification.toLowerCase().includes(aux_search.toLowerCase()) ||
                third.name.toLowerCase().includes(aux_search.toLowerCase())) ||
                third.email.toLowerCase().includes(aux_search.toLowerCase())
        )
        SetThirds(aux_inv)
        if (aux_search.length == 0) {
            SetThirds(thirdFull)
        }
    }

    return (
        <div>
            <NewThird id="new-thirds" type={props.type}></NewThird>
            <div className='search-box' style={{ margin: "10px" }}>
                <input placeholder='Busca por nombre o documento' onChange={(e) => findAndAdd(e)} type='search' className='search-input' list='lista-tercero' value={vdinput}  ></input>
                <datalist className='vd-datalist' id='lista-tercero'>
                    {
                        Array.isArray(third) ?
                            third.slice(5).map(item => {
                                return (
                                    <option value={item.identification}>{item.identification}</option>
                                )
                            }) : <option value="-">-</option>
                    }
                </datalist>
            </div>
            <Table
                emptyChild={<NewThird id="new-thirds" type={props.type}></NewThird>}
                maxHeight="50vh"
                width="100%"
                headersList={["Nombre", "Documento", "Email", "Telefono", "Tipo", "Acciones"]}
                data={third}
                excludeList={["id", "third_type", "identifications_id", "users_id"]}
                formats = { {name: { type: 'tooltip' }, email: { type: 'tooltip' }} }
            >
                <ButtonAction btnKey="thirds-add" className='action-btn'>
                    <BsPlus></BsPlus>
                </ButtonAction>
                <EditThird></EditThird>
                <ButtonAction btnKey="thirds-delete" className='action-btn'>
                    <AiFillDelete/>
                </ButtonAction>
            </Table>
        </div>
    )
}

export default ThirdsTable
