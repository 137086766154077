//Custo css import
import './style.css'

//Widgets import
import NewProduct from '../newProduct/view';
import ProductsTable from '../productsTable/view';
import { dispatchCustomEvent } from '../../../utils/dispatch'
import { MdOutlineInventory2 } from 'react-icons/md';
import LoadProductsFromFile from '../loadProductsFromFile/view';
import ModalBtn from '../../../moleculas/modalButton/hub/view';


function ProductsHub(props) {

    function update() {
        dispatchCustomEvent("update-stock",[])
    }

    return (
            <ModalBtn
                width="95vw"
                id="inventario"
                type="hub"
                onClick={update}
                title="Inventario"
                icon={<MdOutlineInventory2></MdOutlineInventory2>}
            >
                <div>
                    <h2 style={{ "margin": "10px" }}>Inventario</h2>
                </div>
                <div className='btn-group-box-hub-prods'>
                    <NewProduct></NewProduct>
                    <LoadProductsFromFile></LoadProductsFromFile>
                </div>
                <ProductsTable
                    forceLoadData={true}
                    compras={true}
                    buy={true}
                    hiddenAction={true}
                    enableDelete={true}
                    enableEdit={true}
                />
            </ModalBtn>
    )
}

export default ProductsHub
