//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';

//React import
import { useState } from 'react';

import { getAccounts } from './controller';

//Widgets import
import { AiOutlineOrderedList } from 'react-icons/ai';
import Cuentas from '../cuentas/view'
import ModalBtn from '../../moleculas/modalButton/hub/view';

function PlanCuentas(props) {

    const [accounts, SetAccounts] = useState([])
    const [accountsFull, SetAccountsFull] = useState([])

    var vdinput;

    function findAndAdd(e) {
        var aux_search = e.target.value
        var aux_inv = accountsFull.filter(accounts => (accounts.account.toLowerCase().includes(aux_search.toLowerCase()) || accounts.description.toLowerCase().includes(aux_search.toLowerCase())))
        SetAccounts(aux_inv)
        if (aux_search.length === 0) {
            SetAccounts(accountsFull)
        }
    }

    function update() {
        getAccounts().then((res) => [SetAccounts(res), SetAccountsFull(res)])
    }

    return (
        <div>
            <ModalBtn
                id="cuentasPlan"
                className='btn-acc-rep-modal'
                type="hub"
                icon={<AiOutlineOrderedList></AiOutlineOrderedList>}
                title="Plan de cuentas"
                onClick={update}
            >
                <h2>Plan de cuentas</h2>
                <div className="searchbox" >
                    <div className='vd-search' style={{ marginTop: "0px" }}>
                        <input placeholder='Buscar cuentas' onChange={(e) => findAndAdd(e)} type='search' className='search-box' list='lista' value={vdinput}  ></input>
                        <datalist className='vd-datalist' id='lista'>
                            {
                                accounts.map(item => {
                                    return (
                                        <option value={item.description}>{item.description}</option>
                                    )
                                })
                            }
                        </datalist>
                    </div>
                </div>
                <div className='cuentas-table-box-2'>
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: "15%" }}>Cuenta</th>
                                <th scope="col" style={{ width: "65%", textAlign: "center" }}>Descripcion</th>
                                <th scope="col" style={{ width: "20%" }}> </th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div className='cuentas-table-box'>
                    <table class="table">
                        <tbody >
                            {
                                accounts.map((account) => {
                                    return (
                                        <tr hidden={account.is_account === 0}>
                                            <td style={{ width: "15%" }}>{account.account}</td>
                                            <td style={{ width: "65%" }}>{account.description}</td>
                                            <td className='action-box-act' style={{ width: "20%" }}>
                                                <Cuentas account={account}></Cuentas>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </ModalBtn>
        </div>
    )
}

export default PlanCuentas
