//Custo css import
import './style.css'
import SellModule from '../../organismos/sellModule/view';
import PayMethods from '../../payment/organismos/paymethods/view';
import ProductsTable from '../../products/organismos/productsTable/view';
import { useEffect, useState } from 'react';

//Classes
import { userSingleton } from "../../classes/User"
import Resolution from '../../organismos/resolution/resolution';

import GeneralLayout from '../../templates/general/view';
import ButtonGroup from '../../moleculas/buttonGroup/view';
import ThirdsModal from '../../thirds/organismos/thirdsModal/view';
import { ButtonBasic } from '../../atomos/buttons';

function Pos() {

    const [terceros, setTerceros] = useState([]);
    const [role, setRole] = useState(userSingleton.roles_id);
    const [showPay, setShowPay] = useState(false);
    const [badge, setBadge] = useState(0);
    const screenWidth = window.innerWidth;

    useEffect(() => {
        window.addEventListener('update-show-pos-pay', () => {setShowPay(false)})
        window.addEventListener('update-badge-pos-pay', (e) => {setBadge(e.detail.data.badge)})
        return () => {
            window.removeEventListener('update-show-pos-pay', () => {setShowPay(!showPay)})
            window.removeEventListener('update-badge-pos-pay', (e) => {setBadge(e.detail.data.badge)})
        }
    }, [])

    useEffect(() => { setTerceros(terceros) }, [terceros])

    function handleRole() {
        userSingleton.chachierMode()
        setRole(userSingleton.roles_id)
    }

    return (
        <GeneralLayout>
            <div className='pos'>
                <Resolution ></Resolution>
                <ButtonGroup>
                    <ThirdsModal id="pos"></ThirdsModal>
                    <ButtonBasic hidden={screenWidth > 600} badge={badge} onClick={() => { setShowPay(!showPay) }}>Pagar</ButtonBasic>
                    <ButtonBasic onClick={() => { handleRole() }}>
                        {
                            role != 3 ? "Modo cajero" : "Desbloquear"
                        }
                    </ButtonBasic>
                </ButtonGroup>
                <div className='pos-box'>
                    <div className='pos-stock'>
                        <ProductsTable
                            width={screenWidth > 600 ? "100%" : "95vw"}
                            tableHeight={screenWidth > 600 ? "63vh" : "65vh"}
                            forceLoadData={true}
                            hidenBuyValue={true}
                            compras={true}
                            buy={true}
                        ></ProductsTable>
                    </div>
                    <div hidden={window.innerWidth > 600 ? false : !showPay} className='pos-pay'>
                        <SellModule>
                            <PayMethods>
                                <ThirdsModal id="sellmodule"></ThirdsModal>
                            </PayMethods>
                        </SellModule>
                    </div>
                </div>
            </div>
        </GeneralLayout>
    )

}

export default Pos
