//Custo css import
import './style.css'

//Boostrap import
import Form from 'react-bootstrap/Form';
import { BiEdit } from 'react-icons/bi';

//React import
import { useEffect, useState } from 'react';

import ModalBtn from '../../../moleculas/modalButton/hub/view';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import ProductsRepositoryImp from '../../../infrastructure/products/products-repository-implementation'
import { userSingleton } from '../../../classes/User';
import { ProductFactory, Product } from '../../../domain/models/products/products';
import TaxRepositoryImp from '../../../infrastructure/taxes/tax-repository-implementation'
import { ButtonBasic } from '../../../atomos/buttons';
import { roleVerify } from '../../../domain/usecase/user/role-verify';
import { useUserAuth } from "../../../../AuthContext"

const EditProduct = ({ data, hidden = true }) => {

    const productRepository = new ProductsRepositoryImp();
    const taxRepository = new TaxRepositoryImp();

    const { user } = useUserAuth();
    var [selltaxes, SetSellTaxes] = useState([])
    var [buytaxes, SetBuyTaxes] = useState([])
    var [isUserAdmin, SetIsUserAdmin] = useState(false)

    useEffect(() => {
        roleVerify(user, SetIsUserAdmin)
    }, [])

    function handleTax() {
        const taxSuscription = taxRepository.get(userSingleton.uid, userSingleton.cid, "sell").subscribe(
            (data) => {
                SetSellTaxes(data)
            },
            (error) => {
                console.log(error)
            }
        )
        const taxSuscription2 = taxRepository.get(userSingleton.uid, userSingleton.cid, "buy").subscribe(
            (data) => {
                SetBuyTaxes(data)
            },
            (error) => {
                console.log(error)
            }
        )
        return () => {
            taxSuscription.unsubscribe()
            taxSuscription2.unsubscribe()
        }
    }

    function clean() {
        var allInputs = document.querySelectorAll('input');
        allInputs.forEach(singleInput => singleInput.value = '');
        var options = document.getElementById('tax_selector')
        options.selectedIndex = 0
    }

    function edit(prod) {
        prod.preventDefault()
        var value
        if (prod.target[2].value === "") {
            value = data.value
        } else {
            value = prod.target[2].value
        }
        var name
        if (prod.target[1].value === "") {
            name = data.name
        } else {
            name = prod.target[1].value
        }
        var taxID
        if (prod.target[3].value === "") {
            taxID = data.name
        } else {
            taxID = prod.target[3].value
        }

        var product = ProductFactory.create(
            data.id,
            data.sku,
            prod.target[1].value,
            prod.target[2].value,
            prod.target[3].value,
            prod.target[4].value,
            prod.target[5].value,
            prod.target[6].value,
        )
        const productCreate = productRepository.edit(userSingleton.uid, userSingleton.cid, data.sku, product).subscribe(
            () => {
                clean()
                dispatchCustomEvent("loading-btn-product-edit", {})
                dispatchCustomEvent("update-stock", [])
                dispatchCustomEvent("notification-popup", { message: "Producto editado" })
                dispatchCustomEvent("close-editProduct-"+data.id+"-modal", [])
            },
            (error) => {
                var err = error.response.data.message
                var messages = {
                    "limit_reached": "Ups, alcanzaste tu limite de prductos aumenta tu plan y remueve todos los limites...",
                    "product_exist": "Existe un producto con el mismo sku."
                }
                dispatchCustomEvent("notification-popup", { message: messages[err], type: "error" })
            }
        )
        return () => productCreate.unsubscribe()
    }

    return (
        <ModalBtn
            id={"editProduct-" + data.id}
            title={<BiEdit></BiEdit>}
            type="action"
            hidden={hidden}
            onClick={handleTax}
        >
            <div>
                <h2 style={{ "position": "absolute", "margin": "15px", "top": "0px" }}>Editar Poducto</h2>
            </div>
            <div>
            <Form id="compra-form" action="javascript:void(0);" onSubmit={(data) => {edit(data)}}>
                    <Form.Group style={{ width: "95%" }}>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="sku" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Sku</Form.Label>
                                <Form.Control type="text" value={data?.sku} disabled={true}/>
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-name" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" defaultValue={data?.name} onChange={(e) => data.name = e.target.value}/>
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Compra</Form.Label>
                                <Form.Control type="text" defaultValue={data?.buy_value} onChange={(e) => data.buy_value = e.target.value}/>
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-buy-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Cantidad</Form.Label>
                                <Form.Control type="text" defaultValue={data?.stock} disabled={!isUserAdmin}/>
                            </Form.Group>
                            <Form.Group className="mb-3" id="buy-sell-val" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Valor Venta</Form.Label>
                                <Form.Control type="text" defaultValue={data?.value} onChange={(e) => data.value = e.target.value}/>
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex" }}>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Venta</Form.Label>
                                <Form.Select name="color" id="tax_selector">
                                    <option value={data?.sell_taxes_id}>
                                        {selltaxes.filter(i => i.id === data?.sell_taxes_id).map(i => i.description)}
                                    </option>
                                    {
                                        selltaxes ?
                                            selltaxes.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" id="formBasicEmail" style={{ "width": "100%", "margin": "5px" }}>
                                <Form.Label>Impuesto Compra</Form.Label>
                                <Form.Select name="color" id="tax_buy_selector">
                                    <option value={data?.buy_taxes_id}>
                                        {buytaxes.filter(i => i.id === data?.buy_taxes_id).map(i => i.description)}
                                    </option>
                                    {
                                        buytaxes ?
                                        buytaxes.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.description}</option>
                                                )
                                            }) : <div></div>
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </Form.Group>
                    <ButtonBasic
                        id="product-edit"
                        title="Guardar"
                        width="96%"
                        showLoading={true}
                        style={{ width: "96%", margin:"15px" }}
                        type="submit"
                    ></ButtonBasic>
                </Form>
            </div>
        </ModalBtn>
    )
}

export default EditProduct
