//Custo css import
import './style.css'

//Boostrap import
import Button from 'react-bootstrap/Button';

//React import
import { useState } from 'react';

import { getReport } from './controller';

//Widgets import
import { HiDocumentReport } from 'react-icons/hi';
import Datepicker from '../datepicker/view'
import ModalBtn from '../../moleculas/modalButton/hub/view';

import ReportsRepositoryImp from '../../infrastructure/reports/reports-repository-implementation';
import { userSingleton } from '../../classes/User';
import { dispatchCustomEvent } from '../../utils/dispatch';

function AccountingReports(props) {

    const [reports, SetReports] = useState([])

    const reportRepository = new ReportsRepositoryImp();

    function update(params) {
        const reportsSuscription = reportRepository.list().subscribe(
            (data) => {
                SetReports(data["data"])
            },
            (error) => {
                console.error('Error fetching referrals:', error);
                SetReports(undefined);
            }
        );
        return () => {
            reportsSuscription.unsubscribe();
        }
    }

    async function getReport(from_date, to_date, report) {
        const reportsSuscription = reportRepository.get(userSingleton.uid, userSingleton.cid, from_date, to_date, report).subscribe(
            (data) => {
                if ("key" in data["data"] && data["data"]?.key != "no_data") {
                    dispatchCustomEvent("datePicker", {"open": true, "loading": false});
                    dispatchCustomEvent("close-all-modal", {});
                    const link = document.createElement("a");
                    link.href = 'https://public-pos.s3.us-west-2.amazonaws.com/'+data["data"]?.key;
                    link.download = data["data"]?.key;
                    link.click();
                } else {
                    dispatchCustomEvent("datePicker", {"open": false, "loading": false});
                    window.alert("No se encontraron datos para el reporte seleccionado")
                }
            },
            (error) => {
                console.error('Error fetching referrals:', error);
            }
        );
        return () => {
            reportsSuscription.unsubscribe();
        }
    }

    return (
        <div>
            <ModalBtn
                id="reports"
                type="hub"
                className='btn-acc-rep-modal'
                onClick={update}
                icon={<HiDocumentReport></HiDocumentReport>}
                title="Reportes"
            >
                <div style={{ overflowY: "scroll", maxHeight: "75vh", marginTop: "40px" }}>
                    <table class="table">
                        <thead style={{ textAlign: "center" }}>
                            <tr>
                                <th scope="col">Reporte</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {
                                reports.map((report) => {
                                    return (
                                        <tr>
                                            <td>{report.name}</td>
                                            <td>
                                                <Datepicker func={getReport} code={report.code}></Datepicker>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </ModalBtn>
        </div>
    )
}

export default AccountingReports
