import ModalBtn from '../../../moleculas/modalButton/hub/view';
import Loading from '../../../atomos/loading/loadingBtn/view';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { BiEdit, BiSave } from 'react-icons/bi';
import { useState } from 'react';
import './style.css'
import { editThird } from '../../../domain/usecase/thirds/edit';
import { validateEmail, validateId } from '../../../utils/validate';
import { dispatchCustomEvent } from '../../../utils/dispatch';
import { ButtonBasic } from '../../../atomos/buttons';

function EditThird(props) {

    const [loading, SetLoading] = useState(false)
    const [third, SetThird] = useState({})

    function handleChange(e) {
        e.preventDefault()
        var aux = third
        var key = e.target.id.replace("third-", "")

        if(key === "email") {
            if(!validateEmail(e.target.value)) {
                e.target.style.borderColor = "red"
            }else{
                e.target.style.borderColor = ""
            }
        }

        if(key === "phone" && e.target.value !== "") {
            if(!validateId(e.target.value)) {
                e.target.style.borderColor = "red"
            }
            else{
                e.target.style.borderColor = ""
            }
        }

        aux[key] = e.target.value
        SetThird(aux)
    }

    function handleSubmit() {
        var data = third
        if((data.email && !validateEmail(data.email)) || (data.phone && !validateId(data.phone))) {
            dispatchCustomEvent("loading-btn-third-edit", {})
            dispatchCustomEvent("notification-popup", { message: "Verifica los campos", type: "error" })
            return
        }
        if (!data.name && !data.email && !data.phone) {
            dispatchCustomEvent("loading-btn-third-edit", {})
            dispatchCustomEvent("notification-popup", { message: "Debe completar los campos", type: "error" })
            return
        }
        if (Object.keys(data).length > 0) {
            editThird(data, props.data.identification, SetThird)
        }
    }

    return (
        <div>
            <ModalBtn
                id={props.data.identification ? "edit-third-"+props.data.identification : "edit-third"}
                title={<BiEdit></BiEdit>}
                type="action"
            >
                <div className='inventario-box' style={{ marginTop: "15px" }}>
                    <Form action="javascript:void(0);" id="third-form" onChange={handleChange}>
                        <Form.Group >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control placeholder={props.data.name} id="third-name"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Email</Form.Label>
                            <Form.Control placeholder={props.data.email} id="third-email"></Form.Control>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Telefono</Form.Label>
                            <Form.Control placeholder={props.data.phone} id="third-phone"></Form.Control>
                        </Form.Group>
                        <ButtonBasic
                            onClick={() => [handleSubmit()]}
                            id="third-edit"
                            title="Guardar"
                            width="96%"
                            showLoading={true}
                            style={{ width: "96%", margin:"15px" }}
                        ></ButtonBasic>
                        {/* <Button
                            onClick={(e) => [handleSubmit(e)]}
                            style={{ width: "96%", margin:"15px" }} variant="primary" type="submit">
                            <Loading loading={loading}> <BiSave></BiSave> Guardar </Loading>
                        </Button> */}
                    </Form>
                </div>
            </ModalBtn>
        </div>
    )
}

export default EditThird
